.boeckmann-site-search {

  .tabs {
    border: none;
  }

  .tabs-content {
    border: none;

    .headline-section {
      margin-right: -1.25rem;
      margin-left: -1.25rem;

      @include breakpoint(large) {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem;
      }
    }
  }

  .tabs-title {

    > a {
      text-decoration: none;
      border-bottom: 4px solid $white;

      &:focus {
        outline: none;
      }
    }

    @include breakpoint(small down) {
      width: 100%;
    }
  }

  .tabs-title:not([data-count="0"]):hover > a {
    border-color: $corp-darkred;
  }

  .tabs-title.is-active > a {
    border-color: $corp-darkred;
  }

  .tabs-title[data-count="0"] > a {
    background-color: $light-gray;
    cursor: none;
    pointer-events: none !important;
  }

  .sitesearch-item {
    border-bottom: 1px black solid;
    padding-bottom: 0.625rem;
  }

  .product-grid-item {

    .product-grid-item-shadowwrapper {
      height: 100%;
    }
  }


  .sitesearch-list-pageinator {
    margin-top: 2rem;
  }

  .selectize-input, input, .button, select {
    height: 43px;
  }

  button[type="submit"] {
    margin-top: 25px;
  }

  [list]::-webkit-calendar-picker-indicator {
    display: none;
  }

  .icon-input {
    position: relative;
  }

  .icon-input-after {
    content: '';
    position: absolute;
    right: 15px;
    bottom: 11px;
    width: 23px;
    height: 21px;
    background: url('../../../images/icons/lupe-corpdarkgrey.svg') center center no-repeat;
    background-size: contain;
    z-index: 1;
    cursor: pointer;
  }

  .sitesearch-description > em {
    font-style: normal;
    font-family: $font-bold;
    color: $corp-darkred;
    padding: 2px;
  }

  .sitesearch-item {
    margin-top: 1rem;

    .sitesearch-title {
      font-size: rem-calc(18);
    }
  }

  .sitesearch-more {
    line-height: 34px;
    display: block;
    margin-top: 1px;
  }

}
