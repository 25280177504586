.page__footer {

  &.shop {

    .logo-area {

      .provider {

        .logo {

          picture {
            display: inline-block;
          }

          img {
            max-height: 35px;

            &[src$='.svg'] {
              height: 35px;
            }
          }

          .trustpilot {

            img {
              max-height: 49px;

              &[src$='.svg'] {
                height: 49px;
              }
            }
          }
        }
      }
    }

    .contact-area {
      padding: 41px 0 50px;

      .headline {
        margin-bottom: 0;
        padding-bottom: 20px;
      }

      .some-links {
        display: block;

        ul {
          margin-top: 0;
        }
      }
    }

    .bottom ul li {
      font-size: .75rem;
    }

    @include breakpoint(medium) {

      .provider {
        margin-left: 30px;
        display: flex;
        justify-content: flex-end;

        .logo {

          + .logo {
            margin-left: 25px;
          }
        }
      }

      .contact-area {
        padding: 0;
        min-height: 150px;
        align-items: center;

        .headline {
          padding-bottom: 0;
        }
      }
    }

    @include breakpoint(large) {

      .provider {

        .logo {

          + .logo {
            margin-left: 35px;
          }
        }
      }

      .bottom {
        margin-top: 0;
      }
    }
  }
}
