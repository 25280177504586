.shop-teaser {
  .theme-teaser-image {
    img {
      height: 297px;
      width: 100%;
      object-fit: cover;
    }
  }

  .theme-teaser-layer {
    @include breakpoint(large) {
      bottom: 20px;
      right: 20px;
    }
  }
}
