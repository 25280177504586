.shop-image-text {
  .button.primary,
  .button.secondary,
  .button.tertiary {
    font-size: .875rem;
    cursor: pointer;
    display: inline-block;
    padding: 14px 30px 11px;
  }

  .button.primary,
  .button.primary:hover {
    background-color: $corp-darkred;
    border-color: $corp-darkred;
    color: $white;

  }

  .button.tertiary,
  .button.tertiary:hover {
    background-color: #424242;
    border-color: #424242;
    color: #fff;
  }

  .button.secondary,
  .button.secondary:hover {
    background-color: $white;
    border-color: $black;
    color: $black;
  }

  .button {
    &.fullwidth {
      width: 100%;
    }

    &.arrow-right {
      text-decoration: none;

      &:after {
        content: "\e91b";
        font-family: 'primeicons';
        font-size: 14px;
        padding-left: 5px;
      }
    }

    &.disabled,
    &[disabled],
    &.disabled:hover,
    &[disabled]:hover,
    &.disabled:focus,
    &[disabled]:focus {
      opacity: 0.25;
      cursor: not-allowed;
    }
  }
}

.page__main {
  > .main {
    &:not(.shop) {
      .shop-product-teaser {
        .product-card-wishlist {
          display: none;
        }
        .product-card-actions {
          .product-card-link-cart,
          .product-card-link-request {
            display: none;
          }
        }
      }
      .shop-product-slider {
        .swiper {
          width: calc(100% - 20px);
        }
        .product-card-actions {
          min-height: 50px;
          .product-card-link-cart,
          .product-card-link-request {
            display: none;
          }
        }
      }
    }
  }
}
