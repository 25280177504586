.list-job {

   &-item {

      border-bottom: 1px black solid;
      padding-bottom: 0.625rem;

      h3 {
         line-height: 1.1;
         margin-bottom: 0.625rem;
      }

      &-more {
         @extend h3;
         text-decoration: none;
      }

      &-icon {
         width: 1.6rem;
         height: 1.6rem;
      }

   }

}
