.spare-parts-wrapper {

  .spare-parts-item {
    padding: 12px 20px 20px;
    font-size: rem-calc(14);
    position: relative;
    width: calc(100% + 30px);
    margin: 0 -15px;

    &:nth-child(even) {
      background: $corp-grey;
    }

    > a {
      color: inherit;
      text-decoration: none;
    }

    .spare-image {
      padding-top: 18px;
      text-align: center;
      margin-bottom: 10px;

      @include breakpoint(medium only) {
        margin-bottom: 30px;
      }
    }

    .description,
    .properties {
      margin-bottom: 20px;
    }

    p, span {
      font-size: rem-calc(14);
      line-height: rem-calc(20);
    }

    .h3 {
      line-height: 1.4;
      margin: 18px 0 6px;
    }

    h3 {
      font-size: rem-calc(14);
      line-height: 1.1;
      font-family: $font-roman;
      margin-bottom: 16px;
      border-bottom: solid 1px $corp-darkgrey;
    }

    .topic {
      font-family: $font-bold;
      font-size: rem-calc(14);
      line-height: rem-calc(20);
      margin-top: 8px;
    }

    .price {
      display: block;
      font-size: rem-calc(22);
      line-height: rem-calc(24);
      font-family: $font-bold;
      margin-top: auto;
      text-align: right;

      &.request {
        font-size: rem-calc(18);
      }

      sup {
        font-size: 1em;
        top: -.3em;
      }
    }
  }

  .item-vergleich {
    position: absolute;
    top: 18px;
    left: 20px;
    padding-left: 34px;
    font-family: $font-condensed;
    color: $corp-darkgrey;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 29px;
      height: 17px;
      background: url('../../../images/icons/vergleichen.svg') center center no-repeat;
      background-size: contain;
    }

    &.active {

      &:before {
        left: 0;
        top: 0;
        width: 17px;
        height: 14px;
        background-image: url('../../../images/icons/check.svg');

      }
    }
  }

  @include breakpoint(large) {

    .spare-parts-item {
      width: calc(100% - 20px);
      margin: 0 10px;

      .description,
      .properties {
        margin-bottom: 0;
      }
    }
  }

}

.art-no {
  margin-bottom: 20px;
}

.spare-parts-detail {

  .spare-image {
    margin-bottom: 10px;
  }

  .description,
  .properties {
    margin-bottom: 20px;
  }

  p, span {
    font-size: rem-calc(14);
    line-height: rem-calc(20);
  }

  h1 {
    margin: 6px 0;
  }

  h2 {
    font-size: rem-calc(14);
    line-height: 1.1;
    font-family: $font-roman;
    margin-bottom: 16px;
    border-bottom: solid 1px $corp-darkgrey;
  }

  .topic {
    font-family: $font-bold;
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    margin-top: 8px;
  }

  .price {
    display: block;
    font-size: rem-calc(22);
    line-height: rem-calc(24);
    font-family: $font-bold;
    margin-top: auto;
    text-align: right;

    sup {
      font-size: 1em;
      top: -.3em;
    }
  }

  .download {
    padding-top: 30px;

    .button {
      position: relative;
      display: inline-block;
      padding: 16px 16px 11px 50px;

      &:before {
        content: '';
        position: absolute;
        width: 26px;
        height: 24px;
        left: 12px;
        top: 8px;
        background: url('../../../images/icons/download.svg') 0 0 no-repeat;
        background-size: contain;
      }

      &:hover {

        &:before {
          background-image: url('../../../images/icons/download-white.svg');
        }
      }
    }
  }

  @include breakpoint(medium) {

    .download {
      padding-top: 50px;
    }
  }

  @include breakpoint(large) {

    .description,
    .properties {
      margin-bottom: 0;
    }

  }
}