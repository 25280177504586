$med2large: 992px;

.main {

  &.shop {

    .main-headline {
      padding-top: 25px;

      h1 {
        font-size: 2.125rem;
        max-width: 50%;
      }
      h2,
      .h2 {
        font-size: 1.75rem;
        max-width: 50%;
      }
      p {
        font-size: 0.875rem;
      }
    }

    .vin-search-accordion {

      .next {
        margin-top: 24px;
      }

      .multi-headline {
        color: $corp-darkred;
        font-family: $font-bold-condensed;
        font-size: rem-calc(28);
        margin-bottom: 16px;

        @include breakpoint(medium) {
          margin-bottom: 24px;
        }
      }

      .tarp-hint {
        display: block;
        border-radius: 4px;
        background: $corp-darkred;
        font-family: $font-bold;
        font-size: rem-calc(12);
        line-height: 1;
        color: $white;
        padding: .5rem 1rem 6px;
        margin-top: -8px;
        margin-bottom: 1rem;
      }
    }

    .multilevel-accordion-menu {

      + h2 {
        margin-top: 32px;
      }

      > li {
        margin-bottom: 6px;
      }

      .is-accordion-submenu-parent {

        &[aria-expanded="true"] {

          a {
            &:not(.button) {
              &:after {
                color: $white;
                content: "\e903";
                font-family: "primeicons";
                font-size: rem-calc(12);
                position: absolute;
                right: 0;
                top: 3px;
                width: 24px;
                height: 24px;
                padding: 6px 0 0 6px;
                background: $corp-darkred;
              }
            }
            &.subitem {
              &:after {
                display: none;
              }
            }
          }
        }

        &[aria-expanded="false"] {
          a {
            &:not(.button) {
              &:after {
                color: $white;
                content: "\e902";
                font-family: "primeicons";
                font-size: rem-calc(12);
                position: absolute;
                right: 0;
                top: 3px;
                width: 24px;
                height: 24px;
                padding: 6px 0 0 6px;
                background: $corp-darkred;
              }
            }
          }
        }
      }

      > li {

        > a {
          font-family: $font-bold;
          font-size: rem-calc(18);
          color: $corp-darkgrey;
          border-bottom: solid 1px $corp-darkgrey;
          padding-left: 0;
        }
      }

      .menu > li:not(.menu-text) > a {
        padding: 1.2rem 1rem 1.2rem 0;
      }

      .sublevel-1 {

        li {
          margin-top: 1rem;

          > a {
            font-family: $font-roman;
            font-size: .875rem;
            color: $corp-darkgrey;
            border-bottom: solid 1px $corp-darkgrey;
          }
        }
      }

      .sublevel-2 {

        li {
          > a {
            font-family: $font-condensed;
            font-size: .875rem;
            color: $corp-darkgrey;
            border-bottom: solid 1px $corp-darkgrey;
          }
        }
      }

      .article {
        padding: 4px 0;
        border-bottom: 1px solid #CBCBCB;

        .listing-card-link {
          position: absolute;
          display: block;
          top: 0;
          bottom: 0;
          left: 0;
          width: 100%;

          &:after {
            display: none !important;
          }
        }

        &.replacement-parent,
        &.subarticle-parent {
          padding-bottom: 0;
          border-left: 4px solid #CBCBCB;
        }

        .image-container {
          padding: 20px 30px 20px;
          text-align: center;
          position: relative;

          img {
            max-width: 100%;
            display: block;
            margin: .5rem auto;
          }
        }

        .sold-out {
          display: flex;
          flex-wrap: wrap;
          position: absolute;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
          width: 100px;

          span {
            display: inline-block;
            color: $corp-red;
            font-family: $font-bold;
            font-size: .6rem;
            padding: 3px 3px;
            background: $white;
            text-align: center;
            transform: rotate(-20deg);
          }
        }

        .article-inner {
          position: relative;

          .follow-badge {
            padding-top: 1rem;
            margin-bottom: 1rem;

            .product-badge {
              float: none;
            }

            span {
              display: inline-block;
            }
          }

          .description {
            margin-bottom: 2rem;

            .name-art-no {
              position: relative;
            }
          }

          .order {
            align-items: center;
            justify-content: flex-end;
            padding-bottom: .5rem;
            margin-top: 18px;

            .product-card-link-cart {
              &:after {
                content: "\e9e5";
                font-family: primeicons;
                padding-left: 5px;
              }
            }
            .product-card-link-request {
              &:after {
                content: "\e958";
                font-family: primeicons;
                padding-left: 5px;
              }
            }

            .out-of-date {
              color: $corp-darkred;
              font-family: $font-bold;
              font-size: 0.875rem;
            }
          }

          .name-art-no {

            .tarp-info.has-tip {
              position: relative;
              display: inline-block;
              border-bottom: none;
              font-weight: inherit;
              cursor: pointer;
            }
          }
          .art-no {
            margin-bottom: 7px;
            font-size: rem-calc(12);
            color: #807E7E;
            width: auto;
            flex: 1 1 0;
          }
          .name {
            font-family: $font-bold;
            font-size: 1rem;
            padding-bottom: .5rem;
          }
          .installed-quantity {
            font-size: rem-calc(12);
          }

          .price-and-quantity {
            grid-column: 3;
            grid-row: 2;

            .product-item--total {
              margin-bottom: 4px;
            }

            .quantity-box-holder {
              margin-bottom: 15px;

              .product-item--quantity {
                margin-left: auto;
                max-width: 120px;

                .input-group-button {
                  height: 41px;
                }
              }
            }

            @include breakpoint(small down) {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              .product-item--total {
                text-align: left;
              }
            }

            @include breakpoint(medium) {
              .quantity-box-holder {
                margin-bottom: 30px;
              }
            }
          }

          @include breakpoint(small down) {
            .order {
              width: 100%;

              &.gap {
                gap: 10px;
              }

              .button {
                width: 100%;
              }
            }
          }
          .price {
            margin-right: 25px;
            font-size: .875rem;
          }

          .button-group {
            justify-content: space-between;

            .shopping-cart {
              background: $corp-darkred;
              border-color: $corp-darkred;
              color: $white;

              &.disabled {
                pointer-events: none;
                opacity: .5;
                margin-bottom: 0;
              }
            }

            .button {
              height: 41px;
              font-size: .875rem;

              .pi {
                margin-top: -2px;

                &.pi-shopping-cart {
                  margin-top: 1px;
                }
              }

              + .button {
                margin-left: 8px;
              }

              &.product-card-link-details {
                background-color: #F5F5F5;
                border-color: #F5F5F5;
                padding: 15px 30px;
                height: 41px;

                &:hover {
                  background-color: $white;
                  border-color: $black;
                  color: $black;
                }

                &:after {
                  display: none;
                }

                span {
                  display: none;
                }

                i {
                  &.pi-eye {
                    margin-top: -4px;
                    font-size: 16px;
                  }
                  &.pi-chevron-right {
                    display: none;
                  }
                }
              }

              &.product-card-link-request {
                position: relative;
                padding-top: rem-calc(13);
              }
            }
          }
        }

        .individual-parts,
        .follow-up-parts {
          width: 100%;
          margin-top: 4px;
          background: #FAFAFB;

          > li {
            margin-top: 0;
          }

          .article {
            position: relative;
            border-right: 1px solid #CBCBCB;
            border-bottom: none;
            padding-right: 16px;
            padding-top: 1rem;

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 24px;
              width: calc(100% - 48px);
              height: 1px;
              background: #CBCBCB;
            }

            .order {

              .product-card-link-details {
                background-color: transparent;
                border-color: transparent;

                &:hover {
                  border-color: $black;
                  color: $black;
                }
              }

            }
          }

          > li {

            &:last-child {

              .article {

                &:after {
                  display: none;
                }
              }
            }
          }
        }

        .individual-parts {
          height: 0;
          overflow: hidden;

          &.open {
            height: initial;
          }
        }

        .follow-up-parts {

          span {
            display: inline-block;
          }
        }

        &.subarticle-parent,
        &.replacement-parent {
          .article-inner {
            padding-left: 16px;
          }
        }

      }

      .is-accordion-submenu-parent {

        .article {

          &.subarticle-parent {

            .toggle-subitems {
              display: inline-block;
              position: relative;
              font-size: rem-calc(14);
              left: calc(50% - 9px);
              transform: translateX(-50%);
              margin-top: 1rem;

              @include breakpoint(medium) {
                left: auto;
                transform: none;
                margin-top: 0;
              }

              &:after {
                color: $white;
                content: "\e902";
                font-family: "primeicons";
                font-size: rem-calc(12);
                position: absolute;
                right: 0;
                top: 5px;
                margin-right: -18px;
                width: 24px;
                height: 24px;
                padding: 6px 0 0 6px;
                background: $corp-darkred;
              }

              &[aria-expanded="true"] {

                &:after {
                  content: "\e903";
                }
              }
            }
          }
        }
      }

      @include breakpoint(medium) {

        .article {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .image-container {
            width: 270px;
            padding: 24px 20px 20px;

            img {
              display: inline-block;
              margin: 0;
            }
          }

          .sold-out {
            align-content: center;
            top: 0;
            left: 0;
            height: 100%;
            width: 100px;
            transform: translateX(0);
          }

          .article-inner {
            padding-left: 0;
            width: calc(100% - 270px);
            align-self: stretch;
            display: grid;
            grid-template-columns: auto 30px auto;
            grid-template-rows: 64px 100px auto;

            .follow-badge {
              grid-column: 1/span 3;
              grid-row: 1;
            }

            .description {
              grid-column: 1;
              grid-row: 2/span 2;
              margin-bottom: 0;

              .name {
                padding-bottom: 1.5rem;
              }
            }

            .order {
              width: 100%;
              margin-top: 12px;
              grid-column: 3;
              grid-row: 3;
              align-content: flex-end;
            }

            .button-group {

              .button {

                &.product-card-link-details {

                  &:after {
                    display: inline-block;
                  }

                  &:hover {

                    &:after {
                      border-color: $corp-darkgrey;
                    }
                  }

                  i {
                    &.pi-eye {
                      display: none;
                    }
                    &.pi-chevron-right {
                      display: inline-block;
                    }
                  }

                  span {
                    display: inline;
                  }
                }
              }
            }
          }

          .individual-parts,
          .follow-up-parts {

            .article {
              padding-right: 40px;

              .product-card-link-cart,
              .product-card-link-request {
                span {
                  display: none;
                }
                &:after {
                  padding-left: 0;
                }
              }
            }
          }

          &.subarticle-parent,
          &.replacement-parent {
            .article-inner {
              padding-left: 0;
            }
          }
        }
      }

      @include breakpoint($med2large) {
        .article {

          .description {
            margin-right: 20px;
            width: auto;
            flex: 1 1 0;
          }

          .order {
            display: flex;
            margin-top: 0;
            width: auto;
            flex: 0 0 auto;

            .out-of-date {
              padding: 10px 20px;
            }
          }
        }
      }
    }

    .boeckmann-product-search {

      .tabs {
        border: none;
      }

      .tabs-content {
        border: none;

        .headline-section {
          margin-right: -1.25rem;
          margin-left: -1.25rem;

          &.main-headline {
            padding-top: 0;
          }

          @include breakpoint(large) {
            margin-right: -0.9375rem;
            margin-left: -0.9375rem;
          }
        }
      }

      .tabs-title {

        > a {
          text-decoration: none;
          border-bottom: 4px solid $white;

          &:focus {
            outline: none;
          }
        }

        @include breakpoint(small down) {
          width: 100%;
        }
      }

      .tabs-title:not([data-count="0"]):hover > a {
        border-color: $corp-darkred;
      }

      .tabs-title.is-active > a {
        border-color: $corp-darkred;
      }

      .tabs-title[data-count="0"] > a {
        background-color: $light-gray;
        cursor: none;
        pointer-events: none !important;
      }

      .selectize-input, input, .button, select {
        height: 43px;
      }

      button[type="submit"] {
        margin-top: 25px;
      }

      .search-result-listing {

        .product-card-link-cart {
          &:after {
            content: "\e9e5";
            font-family: primeicons;
            padding-left: 5px;
          }
        }
        .product-card-link-request {
          &:after {
            content: "\e958";
            font-family: primeicons;
            padding-left: 5px;
          }
        }
      }

      .product-card-container {
        .product-card {
          margin-bottom: 0;
          border-bottom: none;
        }

        .replacement-product {
          padding-top: 24px;
          border-left: 10px solid #CBCBCB ;
          background-color: #e6e6e6;
          margin-left: 24px;
        }

        margin-bottom: 24px;
        border-bottom: 1px solid #CBCBCB;
      }

      .product-card {
        flex-direction: row;
        flex-wrap: wrap;
        box-shadow: none;
        padding: 0;
        border-bottom: 1px solid #CBCBCB;
        margin-bottom: 24px;

        .product-card-image {
          width: 100%;
          height: 188px;
          padding: 20px;

          @include breakpoint(medium) {
            width: 270px;
          }

          img {
            max-height: 100%;
          }
        }
      }

      .product-card-body {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        display: grid;
        position: relative;
        grid-template-columns: auto;
        grid-template-rows: 40px auto auto auto;

        @include breakpoint($med2large) {
          grid-template-columns: auto 30px auto;
          grid-template-rows: 40px auto 53px;
        }
      }

      .product-item--name {
        font-family: $font-bold;
        font-weight: normal;
        margin-bottom: 6px;
      }

      .product-item--sku {
        margin-bottom: 22px;
      }

      .product-item--short-desc {
        font-size: rem-calc(14);
      }

      .product-card-top {
        grid-column: 1;
        grid-row: 1;
        margin-bottom: 0;
      }

      .product-badge {
        float: none;

        span {
          padding: 5px 14px 3px;
          display: inline-block;

          + span {
            margin-left: 5px;
          }
        }
      }

      .product-card-details {
        grid-column: 1;
        grid-row: 2;
      }

      .price-container {
        grid-column: 1;
        grid-row: 3;
        padding-top: 16px;

        @include breakpoint($med2large) {
          padding-top: 0;
          grid-column: 3;
          grid-row: 2;
        }
      }

      .product-card-actions {
        grid-column: 1;
        grid-row: 4;
        padding-right: 16px;
        padding-top: 16px;

        .button {
          width: 100%;

          @include breakpoint(medium) {
            width: initial;
          }
        }

        @include breakpoint($med2large) {
          grid-column: 1 /span 3;
          grid-row: 3;
        }
      }

      .product-item--total {
        padding-right: 16px;
        text-align: center;

        @include breakpoint(medium) {
          padding: 0 16px 0 0;
          text-align: right;
        }
      }

    }

  }
}

.tooltip {
  max-width: 560px;
  padding-bottom: .5rem;
}

.search-error {
  font-size: rem-calc(11);
  line-height: 1;
  font-family: $font-bold;
  color: $corp-red;
  padding: 0 6px;
  position: absolute;
  margin-top: 3px;
  display: none;

  &.show {
    display: block;
    z-index: 9;
  }
}