/* TODO: NOT IDEAL PLACED */

.sale-list-filter {

  button {
    cursor: pointer;
    margin-top: 6px;

    &:focus {
      outline: none;
    }

    &.list-view {
      width: 40px;
      height: 30px;
      background: transparent url('../../../images/icons/filter-liste.svg') 0 0 no-repeat;
      background-size: contain;
      margin-right: 15px;

      &.is-active,
      &:hover {
        background-image: url('../../../images/icons/filter-liste-aktiv.svg');
      }
    }

    &.grid-view {
      width: 30px;
      height: 30px;
      background: transparent url('../../../images/icons/filter-kachel.svg') 0 0 no-repeat;
      background-size: contain;

      &.is-active,
      &:hover {
        background-image: url('../../../images/icons/filter-kachel-aktiv.svg');
      }
    }
  }
}

#view {
  margin-bottom: 20px;
}

.tabs-content {

  &.multiple-content {
    border: none;
    background: transparent;
    transition: none;
  }
}

.list-list-style,
.list-grid-style {
  display: none;

  &.is-active {
    display: block;
  }
}

.list-list-style {

  @media only screen and (max-width: $global-width) {
    width: 100%;
    overflow-y: auto;
  }
}

.list-table {
  margin-bottom: 0;
  width: 1180px;

  .product-grid-item-vergleich {

    .search-opened + .page__main & {
      z-index: 0;
    }
  }

  tbody {
    border: none;

    @include breakpoint(768px down) {

      tr {

        &.marker {

          h2 {
            font-size: rem-calc(18);
            padding: 0;
          }

          .name {
            opacity: 0;
          }

          &:before {
            content: attr(data-name);
            font-size: rem-calc(18);
            color: $corp-darkgrey;
            font-family: $font-bold-condensed;
            display: block;
            width: calc(100vw - 40px);
            position: absolute;
            left: 20px;
            padding: 7px 0 0 20px;
          }
        }
      }
    }

  }

  tr, td {
    &[data-href] {
      cursor: pointer;
    }
  }

  tr {

    &:nth-child(even) {
      background: $white;
    }

    &:nth-child(4n+3),
    &:nth-child(4n+4) {
      background: $corp-grey;
    }
  }

  td {
    position: relative;
    vertical-align: top;
    font-size: rem-calc(12);
    padding: 8px 15px 22px;

    @include breakpoint(768px down) {
      padding-bottom: 10px;
    }

    &:first-child {
      padding-left: 10px;
    }

    &.name {
      padding: 8px 10px 0 230px;
    }

    .product-list-item-name {
      font-size: rem-calc(22);
      padding: 10px 0 6px;
    }

    strong {
      font-family: $font-bold;
    }

    .image {
      width: 190px;
      position: relative;
      padding: 0 0 18px 10px;

      @include breakpoint(768px down) {
        width: 90px;
      }

      &.badge {

        &:before {
          content: '';
          position: absolute;
          right: 10px;
          top: 144px;
          width: 22px;
          height: 41px;
          background: url('../../../images/icons/price-tag.svg') center center no-repeat;
          background-size: contain;

          @include breakpoint(768px down) {
            right: 5px;
            top: 55px;
          }
        }
      }
    }

    .product-list-item-mehrwerte {
      list-style: none;
      font-size: rem-calc(14);
      line-height: 1.43;
      margin: 0;

      li {
        margin-bottom: 10px;
      }
    }

    .product-helper-price {
      display: flex;
      flex-direction: column;
      text-align: right;
      padding-right: 5px;

      .product-helper-price-preis {
        white-space: nowrap;
        order: 1;
        margin-top: -5px;

        &.request {
          font-size: rem-calc(18);
          margin-top: 0;
        }

        @include breakpoint(768px down) {
          font-size: rem-calc(18);
          margin-top: -1px;
        }
      }
      .product-helper-price-streichpreis {
        order: 2;
        margin-right: 0;
      }
    }
  }

  .product-grid-item-vergleich {
    margin-top: -27px;
    position: absolute;
    top: 0;
    left: 25px;
    padding-left: 34px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: rem-calc(16);
    text-decoration: underline;
    font-family: $font-condensed;
    color: $corp-darkgrey;
    cursor: pointer;
    z-index: 9;

    &.disabled {
      text-decoration: line-through;
      cursor: default;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 29px;
      height: 17px;
      padding-top: 25px;
      padding-bottom: 10px;
      background: url('../../../images/icons/vergleichen.svg') center center no-repeat;
      background-size: contain;
    }

    &.active {

      &:before {
        left: 0;
        top: 0;
        width: 17px;
        height: 14px;
        background-image: url('../../../images/icons/check.svg');

      }
    }

    @include breakpoint(768px down) {
      font-size: 0;
      line-height: 0;
      position: relative;
      display: block;
      margin-top: -10px;
      left: 30px;
      width: 40px;
      height: 30px;
      margin-bottom: 5px;
      border: solid 1px $line-grey;

      &.disabled {
        cursor: default;
        overflow: hidden;

        &:after {
          content: '';
          width: 50px;
          height: 1px;
          position: absolute;
          top: 12px;
          left: -5px;
          background: $corp-darkgrey;
          transform: rotate(-35deg);
        }
      }

      &:before {
        padding: 0;
        left: 5px;
        top: 6px;
      }

      &.active {

        &:before {
          left: 11px;
          top: 7px;
        }
      }
    }
  }

}

