.list-with-title {

  ul {
    list-style: none;
    margin-top: rem-calc(1);

    li {
      font-family: $font-condensed;

      line-height: 1.24;
      color: $corp-darkgrey;
      position: relative;
      font-size: rem-calc(24);
      padding-left: rem-calc(27);
      margin: rem-calc(10 0 10 0);

      @include breakpoint(medium) {
        font-size: rem-calc(34);
        padding-left: rem-calc(46);
        margin: rem-calc(12 0 12 0);
      }

      a {
        font-size: rem-calc(24);
        font-family: $font-condensed;
        line-height: 1.24;

        @include breakpoint(medium) {
          font-size: rem-calc(34);
        }
      }

      &:before {
        content: '';
        width: rem-calc(27);
        height: rem-calc(21);
        top: rem-calc(5);
        left: rem-calc(-20);
        position: absolute;
        background: url('../../../images/icons/check.svg');
        background-size: cover;

        @include breakpoint(medium) {
          width: rem-calc(37);
          height: rem-calc(29.1);
          top: rem-calc(5);
          left: rem-calc(-20);
        }
      }
    }
  }

  &.smaller-list {

    li {
      font-size: rem-calc(20);
      padding-left: rem-calc(15);

      @include breakpoint(medium) {
        font-size: rem-calc(22);
        padding-left: rem-calc(20);
      }

      a {
        font-size: rem-calc(20);

        @include breakpoint(medium) {
          font-size: rem-calc(22);
        }
      }

      &:before {
        width: rem-calc(20);
        height: rem-calc(16);
        top: rem-calc(1);

        @include breakpoint(medium) {
          width: rem-calc(27);
          height: rem-calc(21);
        }
      }

    }
  }
}