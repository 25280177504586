.boeckmann-trade-fairs {

  &-list {
    padding: 20px 0;

    h5 {
      font-family: $font-bold;
      font-size: rem-calc(22);
      line-height: 1.91;

    }

    .border-right {
      border-right: 1px solid $corp-darkgrey;
    }
  }

  &-description {
    font-size: rem-calc(14);
    line-height: 1.43;

    p, a {
      display: inline;
    }

    a {
      font-family: $font-bold;
      color: $corp-darkgrey;
    }
  }

  &-date {
    font-size: rem-calc(13);
    line-height: 1.54;
  }

  &.paginator {
    margin-top: rem-calc(30);
  }

}

.iframe-trade-fair {

  .iframe-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}