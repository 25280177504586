.smaller {
  font-size: rem-calc(14);
}

.bigger {

  > p {
    font-family: $font-condensed;
    font-size: rem-calc(22);
    line-height: 1.24;

    @include breakpoint(medium) {
      font-size: rem-calc(34);
    }
  }
}

.nowrap {
  white-space: nowrap;
}

.subline {

  span {
    font-family: $font-condensed;
    display: block;
    margin-top: 3px;
    line-height: 1;
  }
}

@include breakpoint(medium) {

  .medium-flex {
    display: flex;
    flex-wrap: nowrap;
  }
}

.full-height {
  height: 100%;
}

.bg-white {
  background: $white;
}
.bg-grey {
  background: $corp-grey;
}

.relative {
  position: relative;
}
// links
.arr-down,
.arr-right,
.scroll2 {

  &:after {
    @include css-triangle-extended(4px, $corp-darkgrey, 'down');
    position: absolute;
    top: 12px;
    right: 0;
  }
}

.arr-right {

  &:after {
    transform: rotate(-45deg);
  }
}

.button {
  display: block;
  color: $corp-darkgrey;
  font-family: $font-bold;
  line-height: 1;
  border: solid 1px $corp-darkgrey;
  padding: 16px 6px 11px;
  text-align: center;
  margin-bottom: 10px;
  text-decoration: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;

  &:hover {
    color: inherit;
  }
}

.btn {
  $root: &;

  &--frame-red {
    color: $corp-darkred;
    border-color: $corp-darkred;

    &:after {
      @include css-triangle-extended(4px, $corp-darkred, 'right');
      margin: 0 0 1px 6px;
      position: initial;
    }

    &:focus {
      color: $corp-darkred;
    }

    &:hover,
    &.is-active {
      background: $corp-darkred;
      color: $white;

      &:after {
        border-color: $white;
      }
    }
  }

  &--frame-grey {

    &:after {
      @include css-triangle-extended(4px, $corp-darkgrey, 'right');
      margin: 0 0 1px 6px;
      position: initial;
    }

    &:focus {
      color: $corp-darkgrey;
    }

    &:hover,
    &.is-active {
      background: $corp-darkgrey;
      color: $white;

      &:after {
        border-color: $white;
      }
    }

  }

  &--yellow {
    background: $corp-yellow;
    border-color: $corp-yellow;

    &:after {
      @include css-triangle-extended(4px, $corp-darkgrey, 'right');
      margin: 0 0 1px 6px;
      position: initial;
    }

    &:focus {
      color: $corp-darkgrey;
    }

    &:hover,
    &.is-active {
      background: $corp-yellow-hover;
      border-color: $corp-yellow-hover;
    }
  }

  &--darkgrey {
    background: $corp-darkgrey;
    color: $white;
    border-color: $corp-darkgrey;

    &:focus {
      color: $white;
    }

    &:hover {
      background: $corp-darkgrey-hover;
      color: $white;
      border-color: $corp-darkgrey-hover;
    }
  }

  &--compare {

    &.disabled {
      background: $corp-darkgrey-hover;
      text-decoration: line-through !important;
      cursor: default;
    }

    &.active {
      cursor: pointer;

      &:before {
        left: 14px;
        top: 0px;
        width: 17px;
        height: 17px;
        background-image: url('../../../images/icons/check.svg');
      }

    }

    &:before {
      content: '';
      display: inline-block;
      width: 26px;
      height: 16px;
      margin: 0 10px -4px 0;
      background: url('../../../images/icons/vergleichen-white.svg') 0 0 no-repeat;
      background-size: contain;
      position: initial;
    }
  }

  &--sheet {
    padding: 9px 6px 11px;
    max-width: 320px;

    &:before {
      content: '';
      display: inline-block;
      width: 28px;
      height: 26px;
      margin: 0 10px -8px 0;
      background: url('../../../images/icons/download-white.svg') 0 0 no-repeat;
      background-size: contain;
      position: initial;
    }

  }
}

.hidden {
  visibility: hidden;
}

.main__content {
  section {
    margin-bottom: 25px;

    &.margin-bottom-large {
      margin-bottom: 60px;
    }

    &.margin-bottom-medium {
      margin-bottom: 25px;
    }

    &.margin-bottom-small {
      margin-bottom: 12px;
    }

    @include breakpoint(large) {
      margin-bottom: 40px;

      &.margin-bottom-large {
        margin-bottom: 128px;
      }
      &.margin-bottom-medium {
        margin-bottom: 40px;
      }
    }
  }
}

.pt-20 {
  padding-top: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.grid-container {

  &.full-content {
    max-width: rem-calc(1440px);
    padding: 0;
  }
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}
