.shop-product-teaser {
  @include breakpoint(small down) {
    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .shop-block-image img {
    @include breakpoint(small down) {
      height: 100vw;
    }

    height: 100%;
    max-height: 560px;
    object-fit: cover;
  }

  .product-card-image {
    img {
      height: 100%;
      max-height: 350px;
    }
  }

  .product-card {
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  .product-card-top {
    position: absolute;
    top: 10px;
    left: 10px;
    width: calc(100% - 20px);
  }

  .product-card-wishlist {
    position: absolute;
    top: 0;
    right: 0;
  }

  .product-item--total {
    position: absolute;
    bottom: 28px;
    left: 20px;
  }

  .product-card-image {
    margin-top: 30px;
  }

  .product-card-link-details {
    padding: 10px 18px;
    background-color: $white;
    border-color: $white;
    font-size: 0.875rem;
    cursor: pointer;
    display: inline-block;
    margin-right: 5px;

    span {
      display: none;
    }

    &:after {
      content: "\e966";
      font-family: primeicons;
    }
  }

  .product-card-link-cart {
    padding: 10px 18px;

    span {
      display: none;
    }

    &:after {
      content: "\e9e5";
      font-family: primeicons;
    }
  }

  &.imageright {
    .grid-x {
      flex-direction: row-reverse;
    }
  }
}
