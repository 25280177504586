@import '~foundation-sites/scss/settings/settings';

$header-styles: ();
$header-defs: ();

$global-font-size: 100%;
$global-lineheight: 1.44;
$global-width: rem-calc(1210);

$black: #000;
$white: #fff;
$line-grey: #a1a1a1;
// corporate colors
$corp-red: #e1000a;
$corp-darkred: #9b1414;
$corp-yellow: #ffe400;
$corp-yellow-hover: #fff178;
$corp-green: #73b441;
$corp-grey: #e9e9e9;
$corp-darkgrey: #424242;
$corp-darkgrey-hover: #6f6f6f;
$corp-lightgrey: #e5e5e5;

$font-bold: 'Helvetica Neue Bold', Arial, Helvetica, sans-serif;
$font-bold-condensed: 'Helvetica Neue Bold Condensed', Arial, Helvetica, sans-serif;
$font-condensed: 'Helvetica Neue Condensed', Arial, Helvetica, sans-serif;
$font-roman: 'Helvetica Neue Roman', Arial, Helvetica, sans-serif;

$foundation-palette: (
    primary: $corp-darkred,
    secondary: $corp-darkgrey,
    success: $corp-green,
    warning: $corp-yellow,
    alert: $corp-red,
);

$body-background: $white;
$body-font-color: $corp-darkgrey;
$body-font-family: 'Helvetica Neue Roman';

@include add-foundation-colors;
$anchor-color: $corp-darkred;
$anchor-color-hover: $corp-darkred;
$anchor-text-decoration: underline;
$anchor-text-decoration-hover: underline;

$breakpoints: (
        small: 0,
        medium: 640px,
        large: 1024px,
        intermediate: 1072px,
        xlarge: 1200px,
        xxlarge: 1440px,
);

$breakpoint-classes: (small medium large intermediate xlarge);

$grid-container-padding: (
        small: 40px,
        large: 30px
);

$grid-container: $global-width;
$grid-margin-gutters: (
        small: 10px,
        large: 20px
);

$accordion-title-font-size: rem-calc(22);
$accordion-item-color: $body-font-color;
$accordion-item-background-hover: $corp-lightgrey;
$accordion-content-background: $white;
$accordion-content-border: solid 1px $corp-lightgrey;

$form-label-color: $corp-darkgrey;
$input-color: $corp-darkgrey;
$input-placeholder-color: #cdcdcd;
$input-font-size: rem-calc(14);
$input-padding: rem-calc(10) rem-calc(8) rem-calc(6);
$input-border: 1px solid #CBCBCB;
$input-shadow: none;
$input-shadow-focus: none;

$input-error-color: #cc4b37;

$tab-background: $white;
$tab-color: $corp-darkgrey;
$tab-background-active: $white;
$tab-active-color: $corp-darkgrey;
$tab-item-font-size: rem-calc(14);
$tab-item-background-hover: transparent;
$tab-item-padding: rem-calc(22) rem-calc(20);
$tab-content-color: $body-font-color;
$tab-content-padding: rem-calc(50) 0 rem-calc(35);

$height-meta-header: (
        small: 0,
        medium: 46px
);

$height-main-header: (
        small: 80px,
        medium: 86px,
        large: 140px
);

$height-header: (
        small: 80px,
        medium: 132px,
        large: 186px
);
$height-logo: (
        small: 41px,
        medium: 56px
);

$width-logo: (
        small: 83px,
        medium: 113px
);

$search-input-width: (
        small: 100%,
        large: 100%
);

$search-input-height: (
        small: 30px,
        medium: 40px,
        large: 95px
);

$hero-teaser-height: (
        medium: 560px,
        large: 780px
);

$hero-teaser-slider-height: (
        medium: 420px,
        large: 618px
);

$hero-teaser-layer-width: (
        small: 100vw,
        medium: 500px,
        large: 818px
);

$theme-teaser-height: (
        large: 297px
);

$searchbox-breakpoints: (
        small: 100%,
        medium: 640px,
        large: 1024px,
        xlarge: 1200px,
        xxlarge: 1440px,
) !default;
