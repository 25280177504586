@font-face {
  font-family: 'Helvetica Neue Bold';
  font-weight: normal;
  src: url("../../../fonts/HelveticaNeueLTStd-Bd.otf") format("opentype");
}
@font-face {
  font-family: 'Helvetica Neue Bold Condensed';
  font-weight: normal;
  src: url("../../../fonts/HelveticaNeueLTStd-BdCn.otf") format("opentype");
}
@font-face {
  font-family: 'Helvetica Neue Condensed';
  font-weight: normal;
  src: url("../../../fonts/HelveticaNeueLTStd-Cn.otf") format("opentype");
}
@font-face {
  font-family: 'Helvetica Neue Roman';
  font-weight: normal;
  src: url("../../../fonts/HelveticaNeueLTStd-Roman.otf") format("opentype");
}
@font-face {
  font-family: 'Helvetica Neue Roman';
  font-weight: 700;
  src: url("../../../fonts/HelveticaNeueLTStd-Bd.otf") format("opentype");
}
@font-face {
  font-family: 'Helvetica Neue Condensed';
  font-weight: 700;
  src: url("../../../fonts/HelveticaNeueLTStd-BdCn.otf") format("opentype");
}

@font-face {
  font-family: 'primeicons';
  font-weight: normal;
  src: url("../../../fonts/primeicons.woff") format("woff");
}
