.product-helper-price {

  &-streichpreis {
    font-family: $font-bold;
    font-size: rem-calc(14);
    line-height: 2.29;
    color: #666666;
    text-decoration: line-through;
    margin-right: 1rem;
  }

  &-preis {
    font-family: $font-bold;
    font-size: rem-calc(24); //34px;
    line-height: 0.94;
    letter-spacing: normal;
    color: $corp-darkgrey;

    sup {
      font-size: 1em;
      top: -0.3em;
    }
  }

  .inkl {
    font-size: rem-calc(14);
    display: block;

    sup {
      font-size: 1em;
      top: -0.3em;
    }
  }

}
