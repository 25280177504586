.shop-product-slider {
  --swiper-navigation-size: 32px;
  --swiper-navigation-color: $black;
  --swiper-navigation-sides-offset: calc(50% - 60px);
  --swiper-navigation-top-offset: 88%;

  @include breakpoint(medium up) {
    --swiper-navigation-sides-offset: 15px;
    --swiper-navigation-top-offset: 50%;
  }

  @include breakpoint(1400px up) {
    // --swiper-navigation-sides-offset: -60px;
  }

  @include xy-grid;

  .swiper {
    @include breakpoint(small down) {
      padding-bottom: 80px;
    }
  }

  .swiper-wrapper {
    width: calc(100% + 15px);
  }

  .slider-product-card {
    @include breakpoint(large up) {
      width: calc(25% - 15px);
      margin-right: 15px;
    }
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .product-card-image {
    margin: 16px 0 34px;
    width: calc(100% - 32px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

    img {
      max-height: 200px;
    }

    .product-item-link {
      display: inline-block;
    }
  }

  .product-card-actions {
    width: calc(100% - 32px);
    margin-top: 0;
    padding-bottom: 28px;
  }

  .product-card-body {
    width: calc(100% - 32px);
    margin-top: auto;
    margin-bottom: 4px;
  }

  .product-item--name {
    font-size: rem-calc(12);
    margin-bottom: 0;
  }

  .product-card-wishlist {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .product-item--total {
    position: absolute;
    right: 70px;
    //bottom: 42px;
    font-size: rem-calc(16);
  }

  .product-card-link-cart,
  .product-card-link-request {
    padding: 8px 9px 7px;

    span {
      display: none;
    }

    &:after {
      content: "\e9e5";
      font-family: primeicons;
      font-size: rem-calc(20);
    }
  }

  .product-card-link-request {

    &:after {
      content: "\e958";
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background: var(--white, #FFF);
    box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.20);
    padding: 25px;

    &.swiper-button-disabled {
      pointer-events: auto;
    }
  }

  @include breakpoint(medium) {

    .product-card-image {
      margin-bottom: 40px;
      padding: 0 40px;

      img {
        max-height: 334px;
      }
    }

    .product-item--name {
      font-size: rem-calc(16);
    }

    .product-item--total {
      font-size: rem-calc(18);
    }

    .swiper-button-next,
    .swiper-button-prev {
      margin-top: -6.25rem;
    }
  }

  .button-height {
    height: 40px;
  }
}


