.product-card {
  clear: both;
  display: flex;
  position: relative;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.04);
  padding: 16px;
  margin-bottom: 20px;
}

.button.product-card-link-details {
  background-color: #F5F5F5;
  border-color: #F5F5F5;
}

.product-card-wishlist {
  color: $corp-darkgrey;
  text-align: right;
  position: relative;
  z-index: 1;

  &:after {
    content: '\e984';
    font-family: primeicons;
  }

  &.active:after {
    content: '\e9e1';
    color: $corp-darkred;
  }
}

.product-card-image {
  text-align: center;
  margin-bottom: 16px;
}

.product-card-body {
  margin-bottom: 16px;
}

.product-card-details {
}

.product-item--short-desc {
  font-size: rem-calc(12px);
}

.product-card-actions {
  text-align: right;
  margin-top: auto;
}

.product-listing-view {
  padding-top: 40px;

  i {
    cursor:pointer;
    opacity: .3;
    transition: opacity 0.3s;

    &.active {
      opacity: 1;
    }
  }

}

.product-listing {
  .product-card-link-cart {
    &:after {
      content: "\e9e5";
      font-family: primeicons;
      padding-left: 5px;
    }
  }

  .product-card-link-request {
    &:after {
      content: "\e958";
      font-family: primeicons;
      padding-left: 5px;
    }
  }

  .product-item--total {
    .price-request {
      line-height: 1;
      font-size: 1.125rem;
      display: block;
    }

  }

  &.tiles-view {
    @include xy-grid;
    margin-left: -0.625rem;
    margin-right: -0.625rem;

    .product-card {
      flex-direction: column;
      @include xy-cell(6, $gutters: 20px);

      @include breakpoint(large up) {
        @include xy-cell(4, $gutters: 30px);
      }
    }

    .product-card-image {
      margin-top: 30px;
      max-height: 300px;

      img {
        height: 100%;
        width: auto;
        max-height: 165px;
      }
    }
    .product-card-top {
      position: absolute;
      top: 15px;
      width: calc(100% - 35px);
    }

    .product-card-body {
      margin-bottom: 0;
    }

    .product-card-details {
      height: 100%;
      padding-bottom: 50px;
    }

    .product-item--total {
      position: absolute;
      bottom: 30px;
      right: 65px;
      font-size: 1.125rem;

      .price-request {
        margin-bottom: -5px;
      }

      @include breakpoint(medium up) {
        bottom: 23px;
        font-size: 1.625rem;

        .price-request {
          margin-bottom: 10px;
        }
      }

      .base-price {
        display: none;
      }
    }

    .product-card-actions{
      position: absolute;
      bottom: 15px;
      right: 15px;
    }

    .product-card-link-cart,
    .product-card-link-request {
      padding: 11px;
      position: relative;
      z-index: 1;

      span {
        display: none;
      }

      &:after {
        padding-left: 0;
      }
    }

    .product-card-link-details {
      display: none;
    }

    .product-item--short-desc {
      display: none;
    }

    .full-card-link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  .listing-card-link {
    display: none;
  }

  &.listing-view {
    .product-card {
      flex-direction: row;
      @include xy-cell(12, $gutters: 0);

      .listing-card-link {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }



    .product-card-image {
      width: 150px;
      margin-right: 10px;
      position: relative;

      @include breakpoint(medium up) {
        width: 270px;
      }

      img {
        max-width: 250px;
        max-height: 170px;

        @include breakpoint(small down) {
          width: 100%;
        }
      }
    }

    .product-card-body {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }

    .product-card-details {
      padding-bottom: 20px;
      position: relative;

      @include breakpoint(medium up) {
        padding-right: 140px;
        padding-bottom: 40px;
      }

      .listing-card-link {
        position: absolute;
        display: block;
        top: 0;
        bottom: 20px;

        @include breakpoint(medium up) {
          width: calc(100% - 140px);
          bottom: 40px;
        }
      }
    }

    .product-item--total {
      margin-bottom: 40px;

      .price-request {
        font-size: 1.125rem;
      }

      @include breakpoint(medium up) {
        position: absolute;
        top: 45px;
        right: 16px;
      }

      @include breakpoint(360px down) {
        margin-bottom: 90px;
      }
    }

    .product-card-actions {
      position: absolute;
      bottom: 16px;
      right: 16px;

      .button {
        @include breakpoint(small down) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
}

.product-listing-sort {
  .input-group {
    position: relative;

    select {
      padding-top: 5px;
      padding-left: 35px;
      font-weight: bold;
    }
  }

  .pi {
    position: absolute;
    top: 10px;
    left: 10px;
  }

}

.product-listing-head {
  border-bottom: 1px solid #000;
  padding-bottom: 15px;
  margin-bottom: 24px;

  legend {
    font-size: rem-calc(14px);
    font-weight: bold;
  }
}

.product-card-top {
  margin-bottom: 10px;
}

.product-badge {
  float: left;

  span {
    border-radius: 2px;
    padding: 7px 12px 5px;
    font-size: 12px;
    line-height: 16px;
    font-weight: bold;

    &.badge-new {
      background-color: #2E5415;
      color: $white;
      margin-left: 4px;
    }

    &.badge-sale {
      background-color: #C50606;
      color: $white;
    }

    &.badge-hot {
      background-color: #F5F5F5;
    }

    &.badge-has-follow-up {
      background-color: #C50606;
      color: $white;
    }

    &.badge-is-follow-up {
      background-color: #2E5415;
      color: $white;
    }
  }
}

.info-card {
  background: $white;
  position: relative;
  padding: 20px 20px 6px;
  border: 1px solid #cbcbcb;

  .info-field {
    border-bottom: 1px solid #d7cece;
    margin-bottom: 14px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    &.delivery {
      width: 100%;
      padding-bottom: 12px;

      .field-status {

        .status-group {
          line-height: 1;
        }

        .icon-svg {
          fill: #4caf50;
        }
        .on-request {
          display: none;
        }

        &.yellow {

          .icon-svg {
            fill: #FFE200;
          }
          .on-request {
            display: block;
          }
          .in-stock {
            display: none;
          }
        }
      }
    }
  }

  .title-text {
    font-family: $font-bold;
    font-size: rem-calc(18);

    &.level2 {
      font-size: rem-calc(16);
      padding-top: 7px;
    }
  }

  .status-group {

    svg {
      width: 0.9rem;
    }
  }

  .status-text {
    font-size: rem-calc(14);
    padding-top: 2px;
  }

  .available-sizes,
  .available-colors {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 16px;
  }

  .available-size-btn {
    font-size: rem-calc(14);
    line-height: 1;
    width: 56px;
    padding: 12px 6px 9px;
    border: 1px solid #cbcbcb;

    &.product-variant-attribute-selected {
      outline: 4px solid #cbcbcb;
      outline-offset: -5px;
    }
  }

  .available-color-btn {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    border: 1px solid #cbcbcb;

    &.product-variant-attribute-selected{
      outline: 5px solid #cbcbcb;
      outline-offset: -5px;
    }
  }

  .icon-svg {
    height: rem-calc(18);
  }
}

.reveal {

  &.small {

    &.basket-overlay {
      background: $white;
      padding-bottom: 30px;

      .product-card {
        box-shadow: none;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: 14px;
      }

      .product-card-image {
        width: 272px;
        padding: 10px 10px 10px 16px;

        a {
          display: block;
        }

        @include breakpoint(medium down) {
          img {
            max-height: 175px;
          }
        }
      }

      .headline {
        font-family: $font-bold;
        font-size: rem-calc(22);
        line-height: 1.125;
        width: 100%;
        padding-right: 30px;
        margin-bottom: 12px;

        @include breakpoint(medium down) {
          font-size: 1rem;
        }
      }

      .close-button {
        top: 25px;
        right: 50px;

        span {
          display: none;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          background-size: contain;
          background: url('../../../images/icons/close.svg') 0 0 no-repeat;
        }
      }

      .product-card-body {
        padding-right: 1rem;

        .product-item--name {
          margin-bottom: 6px;
        }

        .product-item--sku {
          margin-bottom: 24px;
        }

        + hr {
          border-color: #CBCBCB;
          margin: 0;
        }
      }

      .product-card-actions {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        width: 100%;

        .button {
          padding: 27px 40px 23px 16px;
          margin-bottom: 0;

          span {
            display: inline-block;
            line-height: 1;
          }

          &.continue-shopping {
            border: solid 2px #121212;
            padding: 25px 16px 21px 40px;
            position: relative;

            &:before {
              content: "\e9e5";
              font-family: primeicons;
              font-size: 16px;
              position: absolute;
              margin-left: -26px;
              margin-top: -4px;
            }
          }

          &.product-card-link-request {
            position: relative;

            &:after {
              content: "\e958";
              font-family: primeicons;
              position: absolute;
              margin-left: 10px;
              margin-top: -1px;
            }
          }

          &.product-card-link-cart {
            position: relative;

            &:after {
              content: "\e980";
              font-family: primeicons;
              position: absolute;
              margin-left: 10px;
              margin-top: -1px;
            }
          }

          + .button {
            margin-top: 16px;
          }
        }
      }

      .shop-product-parts {
        margin-top: 22px;
        margin-bottom: 0;

        h3 {
          font-size: rem-calc(26);
          margin-bottom: 10px;
        }

        .item {
          margin-bottom: 16px;

          .product-part-card {
            padding: 16px 16px 6px;
          }

          .product-card-body {
            padding: 0;
          }

          .product-card-image {
            width: 100%;
            padding: 10px;
            margin: 0;
          }

          .product-card-actions {
            margin-top: 20px;
            display: block;
            text-align: center;

            .button {
              float: none;
              padding: 0;
              background: transparent;
              border: none;
              color: $corp-darkred;
              line-height: 1.57;

              &:after {
                display: none;
              }
            }
          }
        }
      }

      @include breakpoint(medium) {
        width: 68%;
        max-width: 60rem;

        .headline {
          font-size: rem-calc(26);
        }

        .product-card {
          flex-direction: row;
        }

        .product-card-image {
          margin-right: 16px;
          flex: 0 0 auto;

          img {
            max-height: 190px;
          }
        }

        .product-card-body {
          width: auto;
          flex: 1 1 0;
        }
      }

      @include breakpoint(992px) {

        .product-card-actions {
          flex-direction: row;

          .button {

            + .button {
              margin-left: 32px;
              margin-top: 0;
            }
          }
        }
      }

      @include breakpoint(large) {

        .product-card-body {
          display: flex;
          justify-content: space-between;
          align-self: center;
        }
      }

    }
  }
}

.product-listing-filter {
  .product-card-link-cart {
    width: calc(50% - 3px);
  }
}

.swiper {
  --swiper-navigation-color: #424242;
}

.product-page-thumbs {
  height: 120px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  box-shadow: var(--shadow-03-box-shadow,0 8px 20px 0 rgba(0,0,0,.04));

  &.swiper {
    padding: 0 40px;

    @include breakpoint(medium down) {
      display: none;
    }

    @include breakpoint(large) {
      opacity: 0;

      &.swiper-initialized {
        opacity: 1;
        transition: opacity 300ms;
      }
    }
  }

  .swiper-slide {
    text-align: center;

    height: calc(100% - 10px);
    width: 20%;

    .slide-wrapper {
      border: 5px solid transparent;
      height: 79px;
      width: 79px;
    }

    img {
      object-fit: contain;
    }
  }

  .swiper-button-next, .swiper-button-prev {
    --swiper-navigation-sides-offset: 0;
    --swiper-navigation-top-offset: 32px;
    background-color: #fff;
    padding: 10px 0;
    height: 88px;
    width: 32px;
  }

  .swiper-slide-thumb-active .slide-wrapper {
    border: 5px solid #cbcbcb;
  }

  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
  }



  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.product-page-slider {
  overflow: hidden;
  text-align: center;
  padding: 16px;
  position: relative;
  box-shadow: var(--shadow-03-box-shadow,0 0 20px 0 rgba(0,0,0,.04));
  display: flex;

  &.swiper {
    max-height: 480px;
    margin-bottom: 30px;
    margin-top:30px;
    opacity: 0;

    &.swiper-initialized {
      opacity: 1;
      transition: opacity 300ms;
    }
  }

  .swiper-slide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;

    padding: 20px 0;
    height: calc(100% - 40px);
    width: calc(100% - 40px);

    img {
      object-fit: cover;
    }
  }

  --swiper-navigation-size: 20px;

  .swiper-button-next,
  .swiper-button-prev {
    color: $corp-darkgrey;
    border: 1px solid $corp-darkgrey;
    width: 32px;
    height: 32px;
    background-color: #fff;
    top: auto;
    bottom: 20px;

    @include breakpoint(large up) {
      display: none;
    }
  }

  .swiper-button-next {
    left: 50px;
    right: auto;
  }
}

.shipping-info {
  display: block;
  font-size: rem-calc(12);
  font-family: $font-roman;
}

.shop-item {
  .mfp-figure {
    &:after {
      background: $white;
    }
  }
}




