.boeckmann-faq {

  .accordion {
    margin-bottom: 0;
  }

  .accordion-item {
    background: $corp-grey;

    .main__content & {
      margin-bottom: 10px;

      .accordion-content {
        border-bottom: solid 1px $corp-lightgrey;

        > section {

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .accordion-title {
      text-decoration: none;
      border-bottom: 0;
      font-size: rem-calc(22);
      font-family: $font-condensed;
      padding: 20px 50px 18px 20px;

      &:before {
        right: 1rem;
        margin-top: -14px;
        content: '';
        width: 25px;
        height: 25px;
        transition: all .3s ease;
        background-image: url('../../../images/icons/acc-open.svg');
        background-size: contain;
        background-repeat: no-repeat;

      }

      &:focus,
      &:hover {
        color: inherit;
        background-color: transparent;
      }
    }

    .accordion-content {
      padding: 20px;
    }

    &.is-active {

      > .accordion-title {

        &:before {
          transform: rotate(135deg);
          transition: all .3s ease;
          background-image: url('../../../images/icons/acc-open.svg');

        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .list-with-title {

    ul {

      li {
        font-size: rem-calc(22);

        &:before {
          width: 30px;
          height: 24px;
          top: 0;
        }
      }
    }
  }

  .boeckmann-faq-link {

    a {
      display: inline-block;

      &.standard {
        font-family: $font-bold;
        font-size: rem-calc(16);
        line-height: 1.25;
        color: $corp-darkred;
        text-decoration: none;

        &:after {
          @include css-triangle-extended(4px, $corp-darkred, 'right');
          margin-left: 5px;
        }
      }
    }
  }

}