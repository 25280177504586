@import "~foundation-sites/scss/components/pagination";

$pagination-font-size: rem-calc(14);
$pagination-margin-bottom: 70px;
$pagination-item-color: $corp-darkgrey;
$pagination-item-padding: 8px 11px 5px;
$pagination-item-spacing: 0;
$pagination-radius: 0;
$pagination-item-background-hover: $light-gray;
$pagination-item-background-current: transparent;
$pagination-item-color-current: $corp-darkred;
$pagination-item-color-disabled: $corp-lightgrey;
$pagination-ellipsis-color: $corp-darkgrey;

$pagination-mobile-items: true;
$pagination-mobile-current-item: true;
$pagination-arrows: true;


@include foundation-pagination();

.pagination {
	text-align: center;

	a {
		text-decoration: none;
	}

	button {
		cursor: pointer;
	}

	ul {
		margin: 0;
		line-height: 1;
	}

	li {
		border: 1px solid $corp-darkgrey;
		border-right: none;

		&:last-child {
			border-right: 1px solid $corp-darkgrey;
		}
	}

	.ellipsis {
		padding: $pagination-item-padding;
		padding-left: 0;
		padding-right: 0;
	}

	.pagination-next,
	.pagination-previous,
	.pagination-first,
	.pagination-last {
		&:before, &:after {
			content: '';
			margin: 0;
			height: 10px;
			width: 8px;
			position: relative;
			display: inline-block;
			background: url("../../../images/icons/arrow_grey.svg") no-repeat center center;
			background-size: contain;
		}

		&:before {
			left: 1px;
		}
		&:after {
			left: -1px;
		}
	}

	.pagination-next,
	.pagination-previous {
		&:before {
			display: none;
		}
		&:after {
			left: auto;
		}
	}

	.pagination-previous,
	.pagination-first {
		&:before, &:after {
			transform: rotate(-180deg);
		}
	}

	.pagination-last,
	.pagination-first {
		padding-left: 7px;
		padding-right: 7px;
	}
}

.sale-list-pageinator {
	margin-bottom: $pagination-margin-bottom;

	.pagination {
		margin-bottom: 0;
	}
}