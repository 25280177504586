// todo: remove .pimcore_area_boeckmann classes when new toolbox without wrappers is in all branches
.pimcore_area_boeckmann-intro-text,
.intro-text {
  margin-bottom: 35px;

  + .pimcore_area_boeckmann-intro-text,
  .intro-text {
    margin-top: -23px;
  }
}

.content-link {

  .arr-right {
    text-align: right;

    &:after {
      right: 2px;
      top: 50%;
      margin-top: -4px;
      transform: rotate(-45deg) translateY(-50%);
    }

    a {
      padding-right: 20px;
      text-decoration: none;
      font-family: $font-bold;
      color: $corp-darkgrey;
      display: inline-block;
    }
  }
}