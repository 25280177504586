@mixin responsive-size(
  $heights: null,
  $widths: null
) {

  @each $bp-name, $bp-value in $breakpoints {
    @media (min-width: #{map-get($breakpoints, $bp-name)}) {
      @if type-of($heights) == 'map' {
        height: #{map-get($heights, $bp-name)};
      }
      @if type-of($widths) == 'map' {
        width: #{map-get($widths, $bp-name)};
      }
    }
  }
}

@mixin css-triangle-extended(
  $triangle-width,
  $triangle-color,
  $triangle-direction
) {
  display: inline-block;
  width: rem-calc($triangle-width);
  content: '';
  padding: rem-calc($triangle-width);

  border: solid $triangle-color;
  border-width: 0 1px 1px 0;

  @if ($triangle-direction == down) {
    transform: rotate(45deg);
  }
  @if ($triangle-direction == up) {
    transform: rotate(-135deg);
  }
  @if ($triangle-direction == right) {
    transform: rotate(-45deg);
  }
  @if ($triangle-direction == left) {
    transform: rotate(135deg);
  }
}

@function em($pixels, $context: 16) {
  @return #{calc($pixels/$context)}em;
}