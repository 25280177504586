
.selectize-dropdown {
  [data-selectable] {
    .highlight {
      background: $white;
    }
  }
}

.selectize-control {
  border-radius: 0;

  &.multi {
    .selectize-input {
      &.has-items {
        padding-left: 10px;
        padding-right: 30px;
      }
      &.disabled [data-value] {
        color: $corp-grey;
        text-shadow: none;
        background: none;

        &, .remove {
          border-color: #e6e6e6;
        }
        .remove {
          background: none;
        }
      }
      [data-value] {
        text-shadow: 0 1px 0 rgba(0,51,83,0.3);
        padding-right: 20px;

        &:after, &:before {
          content: '';
          display: block;
          top: 50%;
          right: 5px;
          width: 10px;
          height: 1px;
          padding: 0;
          margin: 0;
          background-color: $corp-darkgrey;
          position: absolute;
          transform: translateY(-50%) rotate(45deg);
          border: none;
        }

        &:before {
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
  }
  .selectize-input {
    padding-top: 10px;
  }
}
.selectize-control .selectize-input input {
  line-height: 22px !important;
  &[placeholder] {
    width: 100% !important;
  }
}

.selectize-control .selectize-input, .selectize-dropdown {
  border: 1px solid #CBCBCB;
  border-radius: 0;
  input {
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $corp-darkgrey;
      font-size:rem-calc(16);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $corp-darkgrey;
      font-size:rem-calc(16);
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $corp-darkgrey;
      font-size:rem-calc(16);
    }
  }
}
.main {

  &:not(.shop) {

    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    textarea,
    .selectize-control .selectize-input,
    .selectize-dropdown {
      border-color: $corp-darkgrey;
    }
  }

}
.selectize-control.single .selectize-input, .selectize-control .selectize-input {
  &:after {
    @include css-triangle-extended(4px, $corp-darkred, 'down');
    border-color: $corp-darkgrey;
    position: absolute;
    margin-top: -3px;
    right: rem-calc(6);
    top: 16px;

    @include breakpoint(medium) {
      right: rem-calc(20);
    }
  }

  &.dropdown-active:after {
    @include css-triangle-extended(4px, $corp-darkred, 'up');
    position: absolute;
    right: rem-calc(6);
    top: rem-calc(20);

    @include breakpoint(medium) {
      right: rem-calc(20);
    }
  }
}

.selectize-dropdown {
  .optgroup-header {
    padding-top: 10px;
    font-family: $font-bold;
    font-size:rem-calc(18);
  }
  .optgroup {
    border-top: 1px solid #000;
    &:first-child {
      border-top: 0 none;
    }
  }
}
