.quote {
  background: $corp-grey;
  padding: 60px 10px 50px;

  blockquote {
    position: relative;
    margin-bottom: rem-calc(13);
    color: $corp-darkgrey;
    font-family: $font-bold-condensed;
    font-size: rem-calc(22);
    line-height: 1.24;
    padding: 0;
    border: none;

    &:before,
    &:after {
      top: 60px;
      left: -20px;
      opacity: .1;
      content: '”';
      font-size: rem-calc(180);
      line-height: rem-calc(22);
      position: absolute;
    }
    &:after {
      top: auto;
      left: auto;
      right: -20px;
      content: '“';
      bottom: -70px;
    }
  }

  figcaption {
    font-size: rem-calc(14);
  }

  @include breakpoint(large) {
    padding: 116px 0 92px;

    blockquote {
      font-size: rem-calc(34);

      &:before,
      &:after {
        top: 127px;
        left: -100px;
        font-size: rem-calc(394);
        line-height: rem-calc(42);
      }
      &:after {
        top: auto;
        left: auto;
        right: -100px;
        bottom: -142px;
      }
    }
  }
}