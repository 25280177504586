.modal-link,
.modal-link-gallery {
  position: relative;
  display: block;

  &:after {
    content: '';
    width: rem-calc(50);
    height: rem-calc(50);
    position: absolute;
    right: 0;
    top: 0;
    background: url('../../../images/icons/resize.svg') center center no-repeat;
    background-size: contain;
  }

  .p-d-equipment & {

    &:after {
      width: rem-calc(16);
      height: rem-calc(16);
      right: 13px;
      top: 50%;
      margin-top: -9px;
      background: url('../../../images/icons/info.svg') center center no-repeat;

    }
  }

  .p-d-added-value-hero-slider & {

    &:after {
      right: 0;
      top: auto;
      bottom: 0;

      @include breakpoint(medium) {
        right: 20px;
        bottom: auto;
        top: 0;
      }

      @include breakpoint(1210px) {
        right: calc(50% - 590px);
      }
    }
  }

  .p-d-added-value-double-slider & {

    @include breakpoint(medium) {

      &:after {
        right: 16px;
      }
    }
  }
}

.mfp-title {
  padding: 10px 36px 10px 0;

  strong {
    display: inline-block;
    font-family: $font-bold;
    margin-bottom: .5rem;
  }

  &.absolute {
    position: absolute;
  }
}

.mfp-close-btn-in {

  .mfp-inline-holder {

    .mfp-close {
      color: $white;
      right: -15px;
      margin-top: -40px;
    }

    .responsive-embed {

      &.oneToNineteen {
        padding-bottom: 52.54%;
      }
    }
  }
}