.boeckmann-form {

  .selectize-control {
    div {
      line-height: 22px;
    }
  }

  .selectize-input > input {

    &::-webkit-input-placeholder {
      font-size: rem-calc(14);
    }
    &:-ms-input-placeholder {
      font-size: rem-calc(14);
    }
    &::-moz-placeholder {
      font-size: rem-calc(14);
    }
  }

  .selectize-dropdown,
  .selectize-input,
  .selectize-input input,
  .selectize-control div,
  .selectize-dropdown-content .option {
    font-size: rem-calc(14);
    cursor: pointer;
  }

  .nozoom & {

    .selectize-dropdown,
    .selectize-input,
    .selectize-input input,
    .selectize-control div,
    .selectize-dropdown-content .option {
      font-size: rem-calc(16);

      &::-webkit-input-placeholder {
        font-size: rem-calc(16);
      }
    }
  }

  .form-group {
    &.error {
      input, label {
        margon-bottom: 0;
      }

      margin-bottom: 1rem;
    }
  }

  .selectize-container {
    cursor:pointer;
    display:inline-block;
    position:relative;
    color: black;
    width: 100%;
    padding-bottom: 9px;

    .selectize-dropdown {
      z-index: 29;
    }

    .selectize-control {

      &.multi {
        .item {
          z-index: 20;
          padding: 3px 16px 3px 6px;
          position: relative;
        }
      }

    }

    .select-options {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      z-index: 999;
      margin: 0;
      padding: 0;
      list-style: none;
      border: 1px solid $corp-darkgrey;
      background: $white;

      li {
        padding: rem-calc(0 20px 0 20px);
        line-height: rem-calc(42px);

        &:hover, &:focus {
          background-color:$corp-lightgrey;
        }
      }
    }


    &.with-submit {
      margin-right: rem-calc(49px);
    }
  }

  .selectize-control {

    .search-opened + .page__main & {
      z-index: 0;
    }

    .selectize-input {

      > input {
        height: 22px;
      }
    }

  }

  .selectbox-submitter {
    width: rem-calc(49px);
    height: rem-calc(40px);
    background: $white;
    border-left: solid 1px $corp-darkgrey;
    position: absolute;
    top: 0;
    right: 0;

    &:after {
      @include css-triangle-extended(4px, $corp-darkred, 'right');
    }
  }

  [type='checkbox'] {

    &:focus {
      outline: none;
    }
  }

  textarea {
    padding: rem-calc(22) 0.5rem 0;
    height: 230px;
    font-family: $font-bold;
  }

  .row {
    margin-bottom: .5rem;
  }

  label, input {
    font-family: $font-bold;

    .thin {
      font-family: $font-roman;
    }
  }

  .product-finder {

    input {
      font-family: $font-roman;
    }
  }

  .no-label {
    label {
      display: none;
    }
  }

  input {

    &::-ms-clear {
      display: none;
      width : 0;
      height: 0;
    }
  }

  .checkbox {

    label {
      font-family: $font-roman;
    }
  }


  input[type=checkbox] {
    width: 1px;
    height: 1px;
    padding: 0;
    position: absolute;
    margin: 0;

    + label {
      vertical-align: middle;
      position: relative;
      margin-bottom: 20px;
      margin-left: 0;
      padding-left: 30px;
      display: inline-block;

      &:before {
        content: '';
        width: 20px;
        height: 20px;
        background-color: $white;
        border: 1px solid $corp-darkgrey;
        display: block;
        top: 0;
        left: 0;
        position: absolute;
        font-size: 0;
        line-height: 0;
      }

    }

    &:checked {

      + label {

        &:after {
          content: '';
          background: $corp-darkred;
          width: 10px;
          height: 10px;
          position: absolute;
          top: 5px;
          left: 5px;
          font-size: 0;
          line-height: 0;
        }

      }
    }
  }

  .error {

    input,
    textarea {
      border-color: $corp-red;
    }

    .selectize-control {

      &.single {

        .selectize-input {
          border-color: $corp-red;
        }
      }
    }

    &.checkbox {

      label {
        color: $corp-red;

        a {
          color: $corp-red;
        }
      }

    }
  }

  .error-message {
    font-size: rem-calc(14);
    font-family: $font-bold;
    color: $corp-red;
    padding: 1rem 0;

    ul li a {
      color: $corp-red;
    }
  }

  .submit-row {
    padding-top: 30px;

    .button       {
      display: inline-block;
      padding: 16px 40px 11px;
      cursor: pointer;
    }
  }

  /* moving labels */
  &--moving-labels {
    [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'] {
      padding: rem-calc(22) 0.5rem 0;
      height: rem-calc(42);
    }

    .selectize-container {
      height: rem-calc(58px);
    }
    .selectize-control {

      .selectize-input {
        height: 42px;
        padding: rem-calc(20) 0.5rem 0;
        box-shadow: none;

        input {
          &::placeholder {
            font-size: rem-calc(14) !important;
          }
        }

        > input {
          height: 22px;
        }

        .item {
          font-size: rem-calc(14);
          font-family: $font-bold;
          line-height: 1.5;
        }

        &.dropdown-active {

          &:after {
            margin-top: -2px;
          }
        }
      }

    }

    .form-group {
      &.error {

        margin-bottom: 1rem;

        .selectize-input {
          border-color: $corp-red;
        }

        input, label {
          margin-bottom: 0;
        }

        ul {
          margin: 0;
          li {
            list-style: none;
            color: $corp-red;
          }
        }
      }

      &:not(.checkbox) {
        position: relative;

        label {
          color: $line-grey;
          z-index: 20;
          position: absolute;
          transition: all .2s ease;
          top: 9px;
          left: 8px;

          .search-opened + .page__main & {
            z-index: 0;
          }
        }

        &.filled label {
          top: 7px;
          font-size: rem-calc(12);
          line-height: 1;
        }

        &.selected {
          label {
            display: block;
            top: 7px;
            font-size: rem-calc(12);
            line-height: 1;
          }
        }

        &.file {
          padding-bottom: 16px;

          input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
          }

          label {
            white-space: nowrap;
            max-width: 100%;
            cursor: pointer;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: rem-calc(16) rem-calc(40) rem-calc(11);
            font-size: rem-calc(14);
            font-family: $font-roman;
            line-height: 1;
            position: relative;
            transition: none;
            top: 0;
            left: 0;
            background: $corp-darkgrey;
            color: $white;

            @include breakpoint(medium) {
              font-size: rem-calc(16);
            }

            &:hover {
              background: $corp-darkgrey-hover;
            }
          }

          &.filled {

            label {
              top: 0;
              font-size: inherit;
              line-height: 1;
            }

          }
        }
      }
    }

    .max-size {

      &.size {
        color: $corp-red;
      }
    }
  }

  .order-list {
    font-size: rem-calc(14);
    font-family: $font-bold;

    .prints {
      display: none;
    }

    &.results {

      .noPrints {
        display: none;
      }

      .prints {
        display: inline;
      }
    }

    ul {
      font-family: $font-roman;
      margin: .5rem 0 0 0;
      list-style: none;

      li {
        position: relative;
        cursor: pointer;
        padding-left: 20px;
        margin-bottom: 8px;
        line-height: 1.3;

        &:after {
          content: '-';
          font-family: $font-bold;
          font-size: 1rem;
          position: absolute;
          left: 0;
          top: -2px;
        }


        &:hover {

          &:after {
            content: '\00d7';
            font-size: 1.5rem;
            top: -7px;
          }

        }

      }

      &.noHover {

        li {

          &:after {
            content: '\00d7';
            font-size: 1.5rem;
            margin-top: -5px;
          }
        }
      }
    }
  }

  .input-dropdown-height {
    height: 42px;
  }

  .icon-input {

    position: relative;

    .icon-input-after {
      content: '';
      position: absolute;
      right: 15px;
      bottom: 9px;
      width: 23px;
      height: 21px;
      background: url('../../../images/icons/lupe-corpdarkgrey.svg') center center no-repeat;
      background-size: contain;
      z-index: 1;
      cursor: pointer;
    }

  }

  .sale-list-filter {

    .selectize-container {
      height: 54px;
    }

    .selectize-control {

      &:after {
        content: '';
        position: absolute;
        left: 12px;
        top: 12px;
        width: 23px;
        height: 21px;
        background: url('../../../images/icons/sortieren.svg') center center no-repeat;
        background-size: contain;
        z-index: 1;
      }

      &.single {

        .selectize-input {
          height: 42px;
          padding: rem-calc(13) rem-calc(8) 0 rem-calc(40);

          > .item {
            font-size: rem-calc(14);
            font-family: $font-bold;
          }

          &:after {
            top: 16px;
            border-color: $corp-darkgrey;
          }

          &.dropdown-active {

            &:after {
              margin-top: 3px;
            }
          }

          input[placeholder] {
            width: 100% !important;
            line-height: 22px !important;

            &::placeholder {
              font-size: rem-calc(14) !important;
            }
          }
        }
      }

      .selectize-dropdown {

        .option {
          padding-left: rem-calc(40);
        }
      }

    }
  }

  + section {
    margin-top: 40px;

    @include breakpoint(large) {
      margin-top: 60px;
    }
  }
}

