@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";

.slick-dotted {

  &.slick-slider {
    margin: 0;
  }
}

.slick-dots {

  @include breakpoint(medium) {
    max-width: inherit;
  }

  li {
    width: 60px;
    height: 3px;
    margin: 0 10px;

    button {
      width: 60px;
      height: 20px;
      background: none;
      border-bottom: 3px solid $white;
      opacity: 1;
      padding: 0;

      &:before {
        display: none;
      }
    }

    &.slick-active {

      button {
        @include breakpoint(medium) {
          border-bottom: 6px solid $corp-darkred;
        }
        border-bottom: 3px solid $corp-darkred;
        opacity: 1;
      }
    }
  }
}

.slick-prev,
.slick-next {
  width: 45px;
  height: 45px;
  opacity: 0.9;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: $white;
  z-index: 101;
  &:hover, &:focus {
    outline: none;
    background-color: $white;
    color: transparent;
    &:before {
      opacity: 0.9;
    }
  }
  &.slick-disabled:before {
    opacity: 0.9;
  }
  &:before {
    font-family: inherit;
    color: $corp-darkgrey;
    opacity: 0.9;
  }
}

.slick-prev {
  left: rem-calc(15);

  @include breakpoint(medium) {
    left: 0;
  }

  [dir="rtl"] & {
    left: auto;
    right: rem-calc(15);
    @include breakpoint(medium) {
      right: 0;
    }
  }

  &:before {
    @include css-triangle-extended(4px, $corp-darkgrey, 'left');
    [dir="rtl"] & {
      @include css-triangle-extended(4px, $corp-darkgrey, 'right');
    }
  }
}

.slick-next {
  right: rem-calc(15);
  @include breakpoint(medium) {
    right: 0;
  }

  [dir="rtl"] & {
    left: rem-calc(15);
    @include breakpoint(medium) {
      left: 0;
    }
    right: auto;
  }

  &:before {
    @include css-triangle-extended(4px, $corp-darkgrey, 'right');
    [dir="rtl"] & {
      @include css-triangle-extended(4px, $corp-darkgrey, 'left');
    }
  }
}

