.bg-light-green {
  background-color: #DBDDCD;
}

.bg-white {
  background-color: #FFF;
}

.bg-light-gray {
  background-color: #ECEEEC
}

.shop-image-text {
  img {
    height: 100%;
    object-fit: cover;
  }

  @include breakpoint(medium down) {
    .grid-container {
      padding: 0
    }
  }

  .button {
    align-self: start;
    font-size: 1rem;

    &.tertiary {
      color: #fff;
      background-color: #424242;

      &:hover {
        background-color: #6f6f6f;
      }
    }

    &.arrow {
      &:after {
        font-family: primeicons;
        font-size: 0.875rem;
        content: '\e91b';
        padding-left: 10px;
      }
    }
  }

  .shop-block-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .inner {
      display: flex;
      flex-direction: column;
      gap: 24px;

      p {
        margin-bottom: 0;
      }
    }

    .button-black {
      color: #fff;
      background-color: black;
      display: inline;
      padding-left: 16px;
      padding-right: 16px;
    }

    .arrow-right {
      text-decoration: none;

      &:after {
        content: "\e91b";
        font-family: 'primeicons';
        font-size: 14px;
        padding-left: 5px;
      }
    }

    @include breakpoint(large) {
      padding: 20px 10%;
    }
  }

  &.textleft-imageright {
    .grid-x {
      flex-direction: row-reverse;
    }
  }
}
