@include breakpoint(large) {
  .main-nav {
    align-self: flex-end;
    margin-bottom: rem-calc(-14.5);
    margin-left: rem-calc(28);
    display: flex;

    &.bottom {
      margin-left: 0;
      margin-top: 4px;
    }

    .menu {
      @include breakpoint(medium) {
        height: 65px;
      }

      li {
        position: relative;
        padding-bottom: rem-calc(40);
        transition: all .5s ease-in-out;
        height: rem-calc(35);
        margin-right: rem-calc(25);
        //margin-right: rem-calc(15);

        &:last-child {
          margin-right: 0;
        }

        a {
          font-family: $font-bold;
          font-size: rem-calc(14);
          color: $corp-darkgrey;
          padding: rem-calc(12 13.5 11.2 13.5);
          //padding: rem-calc(12 5.5 11.2 5.5);

          &:after {
            margin-left: rem-calc(8);
            position: relative;
            top: rem-calc(-2);
            -webkit-transition: all .15s ease-in;

            @include css-triangle-extended(3px, $corp-darkgrey, 'down');
          }
        }

        &.no-sub {

          a {

            &:after {
              display: none;
            }
          }
        }

        &:hover, &:active {
          a {
            &:before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 96%;
              height: 4px;
              background-color: $corp-darkred;
            }
          }
        }

        &.active {
          background: $corp-darkred;
          padding-bottom: 0;
          height: rem-calc(75);

          a {
            color: $white;
            background: none;

            &:after {
              @include css-triangle-extended(3px, $white, 'up');
              top: 1px;
              position: relative;
            }
          }

          &:hover, &:active {
            a {
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.bg-layer {
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: none;

  &.open {
    display: block;
  }
}

_:-ms-lang(x),
.bg-layer {
  z-index: 1;
}

.layer-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: rem-calc(15);
}

.mega-layer {
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.15);
  height:  0;
  background: $white;
  z-index: 10;
  transition: all .5s ease-in;
  opacity: 0;
  position: absolute;
  left: 0;

  &.opened {
    position: relative;
    display: inline-block;
    min-height:  440px;
    opacity: 1;
  }

  .navigation-image {
    max-height: 356px;
    width: auto;
    margin-top: -5px;
  }

  .navigation-grid {
    display: flex;
    flex-flow: row wrap;
    margin-left: -41px;
    height:  0;
    transition: all .5s ease-in;
    position: relative;
    z-index: 100;
  }

  &.opened {
    .navigation-grid {
      height: auto;
    }
  }

  .section-box {
    flex: 0 0 auto;

    @include breakpoint(large only) {
      width: 10px;

      img {
        display: none;
      }
    }
  }

  .navigation-box {
    //flex: 1;
    flex: 0 0 auto;
    /*ToDo: Ask if the Navigation should always be over the picture */
    //margin-left: rem-calc(-50);
    margin-right: rem-calc(33);
  }

  .dropdown {

    &.level02, &.level03 {
      margin-top: rem-calc(23);
      margin-right: rem-calc(10);

      > li {
        padding-left: rem-calc(30);

        a {
          font-size: rem-calc(14);
          font-family: $font-bold;
          color: $black;
          padding: rem-calc(13px 27px 13px 0);

          &:after {
            @include css-triangle-extended(3px, $black, 'right');
            right: 0;
            margin-top: 2px;
            position: absolute;
          }
        }

        &:hover, &:active {

          a {
            position: relative;
            &:before {
              content: '';
              position: absolute;
              left: -5%;
              bottom: 4px;
              width: 110%;
              height: 4px;
              background-color: $corp-darkred;
            }

            &:after {
              right: 0;
            }
          }
        }

        &.active {
          background: $corp-darkred;
          padding-bottom: 0;
          margin-right: rem-calc(-53);

          a {
            color: $white;
            background: none;

            &:before {
              display: none;
            }

            &:after {
              @include css-triangle-extended(3px, $white, 'right');
              right: 0;
            }
          }

          &:hover, &:active {

            a {

              &:after {
                right: rem-calc(53);
              }
            }
          }
        }

        &.overview-link {
          margin-bottom: rem-calc(28);

          a {
            font-size: rem-calc(16);
            font-family: $font-bold;
            color: $black;
          }
        }
      }
    }

    &.level02 {

      > li {

        a {

          &.active {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              left: -5%;
              bottom: 4px;
              width: 110%;
              height: 4px;
              background-color: $corp-darkred;
            }

            &:after {
              right: 0;
            }
          }
        }

        &.active {

          a {

            &.active {
              position: initial;
            }
          }

          &:hover {

            a {

              &.active {
                position: relative;
              }
            }
          }
        }
      }
    }
  }
}

.subnavigation-box {

  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  min-height:  440px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.15);
  transition: all .5s ease-in;
  width: 0;
  opacity: 0;
  display: flex;

  .subnavigation-box-content {
    box-shadow: inset 5px 0 10px 0 rgba(0, 0, 0, 0.10);
    background: $white;
    width: 100%;
    height: 440px;

    .section-link {
      margin-left: rem-calc(30);
      margin-top: rem-calc(20);
      display: inline-block;

      &.overview-link {
        position: relative;
        font-size: rem-calc(16);
        font-family: $font-bold;
        color: $black;
        text-decoration: none;
        padding: rem-calc(13 26 13 0);
        visibility: hidden;

        &:before {
          width: 110%;
        }

        &:after {
          @include css-triangle-extended(3px, $black, 'right');
          right: 0;
          margin-top: 5px;
          position: absolute;
        }

        &:hover,
        &:active {

          &:before {
            content: '';
            position: absolute;
            left: -5%;
            bottom: 7px;
            width: 110%;
            height: 4px;
            background-color: $corp-darkred;
          }

        }
      }
    }

    > .grid-x {
      padding-bottom: 12px;
    }


    .level03 {
      display: inline-block;
      margin-top: 6px;
      visibility: hidden;
    }
  }

  &.opened {
    width: 100vw;
    opacity: 1;

    .subnavigation-box-content {

      .section-link {

        &.overview-link {
          visibility: visible;
        }
      }

      .level03 {
        visibility: visible;
      }
    }
  }

  .dropdown {
    opacity: 0;
    transition: all 1s ease-in;

    &.level03 {
      min-width: 280px;

      &.second-part {
        margin-left: rem-calc(30);
      }

      > li {
        padding-left: 0;
        margin-left: rem-calc(30);
        display: table;
        width: 100%;

        &.level03-header {
          font-size: rem-calc(14);
          padding-bottom: rem-calc(8);
          border-bottom: 1px solid #000;
        }

        a {
          display: table-cell;
          width: 85%;
          padding-right: 0;
          &:after {
            display:none;
            position: relative;
          }

          &.active {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              left: -5%;
              bottom: 4px;
              height: 4px;
              background-color: $corp-darkred;
              width: 122.5%;
            }
          }
        }

        span {
          display: table-cell;
          width: 15%;
          @include css-triangle-extended(3px, $black, 'right');
        }

        &:hover, &:active {

          a {
            &:before {
              width: 122.5%;
            }

            &:after {
              position: relative;
            }
          }
        }

        /*&.overview-link {
          margin-bottom: rem-calc(8);

          a {
            &:before {
              width: 110%;
            }

            &:after {
              display: inline-block;
              right: 0;
              margin-left: 26px;
            }
          }
        }*/
      }
    }
  }
  &.opened {
    .dropdown {
      opacity: 1;
    }
  }
}

.nav-switch {
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 200;

  .nav-open &,
  &:hover {
    color: $corp-darkgrey;

    .switch-mobile {

      span {
        background-color: $corp-darkgrey;
      }
    }
  }

  .switch-mobile {
    width: 30px;
    height: 18px;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: $corp-darkgrey;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      border-radius: 1.5px;
      //transition: .15s ease-in-out;

      .nav-open & {
        height: 5px;
        border-radius: 10px;
        background-color: $corp-darkgrey;
      }

      &:first-child {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 8px;
      }

      &:nth-child(4) {
        top: 16px;
      }

      .nav-open &:first-child {
        top: 8px;
        width: 0;
        left: 50%;
      }

      .nav-open &:nth-child(2) {
        transform: rotate(45deg);
      }

      .nav-open &:nth-child(3) {
        top: 7.5px;
        transform: rotate(-45deg);
      }

      .nav-open &:nth-child(4) {
        top: 16px;
        width: 0;
        left: 50%;
      }

    }
  }

  @include breakpoint(medium) {
    display: flex;
    margin-right: 0;

    > span {
      font-size: rem-calc(14);
      font-family: $font-bold;
      text-transform: uppercase;
      padding-top: 2px;
      cursor: pointer;
    }

    .switch-mobile {
      margin-top: 2px;
      margin-left: 13px;
    }

  }
}

@include breakpoint(medium down) {
  .layer-wrapper {
    display: none;
  }

  @keyframes moveIn {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(0);
    }
  }

  .mobile-menu {
    position: fixed;
    height: calc(100vh - 5rem);
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    margin-top:  rem-calc(80);
    padding-top: rem-calc(20);
    transform: translateX(-100%);

    @include breakpoint(medium) {
      height: calc(100vh - 8.125rem);
      margin-top:  rem-calc(130);
    }

    .searchbox-content {

      .boeckmann-form {
        position: relative;
      }

      input {
        @include responsive-size($search-input-height, $search-input-width);
        border: 1px solid $corp-darkgrey;
        color: $corp-darkgrey;
        padding: 5px 30px 5px 10px;

        &::-ms-clear {
          display: none;
          width : 0;
          height: 0;
        }
      }

      .icon-input-after {
        position: absolute;
        right: 7px;
        top: 5px;
        width: 20px;
        height: 20px;
        background: transparent url('../../../images/icons/lupe-corpdarkgrey.svg') center center no-repeat;
        background-size: contain;
        z-index: 1;
        cursor: pointer;
      }

    }

    .language-select {
      height: rem-calc(30);

      option {
        font-family: $font-roman;
      }

      @include breakpoint(medium) {
        height:  rem-calc(42);
      }
    }

    .is-drilldown {
      //cposition: inherit;
      //margin-top: rem-calc(20);

      .menu,
      .drilldown{

        //max-width: 80vw;
        //position: inherit;

        li {
          //position: inherit;
          transition: all .5s ease-in-out;
          border-bottom: 1px solid $corp-darkred;

          a {
            font-family: $font-bold;
            font-size: rem-calc(18);
            color: $corp-darkgrey;
            padding: rem-calc(12 13.5 11.2 13.5);
          }

          &.is-drilldown-submenu-parent {

            > a {

              &:after {
                display: block;
                width: rem-calc(30);
                height: rem-calc(30);
                content: '+';
                border: none;
                position: absolute;
                top: rem-calc(10);
                right: 0;
                font-size: rem-calc(38);
                color: $corp-darkgrey;
                transform: rotate(0deg);
              }
            }
          }

          .is-drilldown-submenu {
            position: absolute;
            max-width: 100vw;
            z-index: 100;
            //padding-right: 10vw;

            li {
              position: relative;
              //margin: 0 15px;

              &.js-drilldown-back {

                a {
                  &:before {
                    @include css-triangle-extended(7px, $corp-darkgrey, 'left');
                  }
                }
              }

              &.active{

                > a {
                  background: none;
                }
              }

              &.is-submenu-item {
                > a {

                  &:after {
                    display: block;
                    width: rem-calc(22);
                    height: rem-calc(30);
                    content: '>>';
                    border: none;
                    position: absolute;
                    top: rem-calc(10);
                    right: rem-calc(13.5);
                    font-size: rem-calc(18);
                    color: $corp-darkgrey;
                  }
                }
              }

              &.is-accordion-submenu-parent{

                > a {

                  &:after {
                    display: none;
                  }
                }
                .submenu-toggle {

                  &:after {
                    @include css-triangle-extended(4px, $corp-darkgrey, 'down');
                  }
                  &[aria-expanded='true'] {
                    &:after {
                      transform: rotate(-135deg);
                    }
                  }
                }

                .is-accordion-submenu {
                  position: relative;
                  max-width: inherit;
                  padding-right: 10vw;
                  border-top: 1px solid $corp-darkred;

                  &.is-active {
                    visibility: visible;
                  }

                  .js-drilldown-back {
                    display: none;
                  }

                  li {
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    .meta-mobile {
      margin-top: 70px;

      .menu {
        display: block;

        li {
          display: block;
          position: relative;
          width: 100%;
          border-bottom: 1px solid $corp-darkred;

          a {
            font-family: $font-bold;
            font-size: rem-calc(18);
            color: $corp-darkgrey;
            padding: rem-calc(12 13.5 11.2 13.5);

            &:after {
              display: block;
              width: rem-calc(22);
              height: rem-calc(30);
              content: '>>';
              border: none;
              position: absolute;
              top: rem-calc(10);
              right: rem-calc(13.5);
              font-size: rem-calc(18);
              color: $corp-darkgrey;
            }
          }
        }
      }
    }
  }

  .nav-open {
    .mobile-menu {
      animation: moveIn 1s;
      transform: translateX(0);
    }
  }

  .level02 {

    .trigger-sub {

      + button {
        width: 100%;

        &:after {
          position: absolute;
          right: 15px;
        }
      }
    }
  }
}

.mobile-menu {

  .is-drilldown {

    .menu {

      li {

        .is-drilldown-submenu {

          li {

            &.js-drilldown-back {
              padding-top: 16px;

              @include breakpoint(small down) {
                padding-top: 4px;
              }
            }
          }
        }
      }
    }
  }
  &.ios {
    height: calc(100vh - 5rem - 100px);
    margin-bottom: 100px;

    @include breakpoint(medium) {
      height: calc(100vh - 8.125rem - 100px);
    }
  }

}

body {

  &.nav-open {
    position: fixed;
    height: 100vh;
    overflow: hidden;

    > .page {
      height: 100vh;
      overflow: hidden;

      .outer {
        height: 100%;
        padding-bottom: 20px;
        overflow: scroll;

        > .grid-container {
          position: relative;

          .is-drilldown {

            .drilldown {

              .level03 {

                li {

                  a {
                    //font-size: rem-calc(16);
                    font-family: $font-roman;

                    &:after {
                      right: 0;
                    }
                  }
                }
              }

            }
          }
        }
      }
    }

    .bg-layer {
      display: block;
    }
  }

  .inside-header {

    &.is-anchored {

      &.is-at-bottom {
        top: 0 !important;
      }
    }
  }
}

.breadcrumbs {
  margin-left: 0;
  margin-bottom: rem-calc(30);

  @include breakpoint(medium) {
    margin-bottom: rem-calc(60);
  }

  li {
    a, span {
      color: $corp-darkgrey;
      text-transform: none;
      text-decoration: none;
      font-size: rem-calc(14);
      font-family: $font-roman;
    }
    &:not(:last-child) {

      &:after {
        @include css-triangle-extended(3px, $corp-darkgrey, 'right');
        position: relative;
        top: rem-calc(-1);
        margin: rem-calc(0 9px 0 5px);
      }
    }
    &:last-child {

      a, span {
        font-family: $font-bold;
        pointer-events: none;
        cursor: default;
        text-decoration: none;
      }
    }
  }
}

.breadcrumb-section {

  &.blog-overview {
    margin-top: rem-calc(-35);
    margin-bottom: 22px;

    .breadcrumbs {
      margin-bottom: 0;
    }
  }
}

.site-toggle {
  padding-top: 38px;
  margin-right: 40px;
  margin-left: 28px;

  li {
    font-family: $font-bold;

    a {
      font-size: rem-calc(14);
      color: $black;
      padding: 0 0 2px;
    }
    &:first-child {
      margin-right: rem-calc(40);
    }
  }

  .to-web {
    font-size: rem-calc(16);
    border-bottom: solid 2px $black;
  }

  .to-shop {
    &:hover {
      color: $corp-darkred;
    }
  }

  @include breakpoint(medium down) {
    //background: blue;
  }

  @include breakpoint(small down) {
    padding-top: 50px;

    li {

      a {
        font-size: rem-calc(10);
        position: relative;

        &:before {
          content: '\e94f';
          font: normal normal normal 22px/1 primeicons;
          position: absolute;
          top: -30px;
          left: 50%;
          margin-left: -11px;
          text-rendering: auto;
          color: $corp-darkred;
          -webkit-font-smoothing: antialiased;
        }

        &.to-web {
          font-size: rem-calc(10);
          border: none;
          color: $corp-darkred;
        }

        &.to-shop {

          &:before {
            content: '\e980';
            color: #121212;
          }

          &:hover {

            &:before {
              color: $corp-darkred;
            }
          }
        }
      }

      &:first-child {
        margin-right: rem-calc(20);
      }
    }
  }
}

.language-selector {

  &.select-wrapper {

    select {
      opacity: 0;

      + .language-select {
        background: none;
        width: 124px;
        margin-top: 0;

        .header-meta & {
          margin-top: -2px;
        }

        &.single {

          > .selectize-input {
            border: none;
            box-shadow: none;
            background: transparent;

            .header-meta & {
              color: $white;
            }

            &:after {
              content: '';
              border: none;
              width: 6px;
              height: 13px;
              padding: 0;
              transform: rotate(0);
              margin-top: -3px;
              right: 0.375rem;
              top: 16px;
              background: url('../../../images/icons/select_dark.svg') 0 0 no-repeat;
              background-size: contain;

              .header-meta & {
                background-image: url('../../../images/icons/select.svg');
              }
            }

            &.dropdown-active {

              &:before {
                display: none;
              }
            }
          }

          .item {
            font-size: .875rem;
          }

          .selectize-dropdown {
            background-image: none;
            box-shadow: none;
            z-index: 999;
            margin-top: 2px;

            .shop & {
              margin-top: 0;
            }
            .selectize-dropdown-content {
              background-color: #fff;
              border: solid 1px $corp-lightgrey;
            }

            .option {
              font-size: .875rem;
              cursor: pointer;

              &.active {
                background: $corp-darkgrey;
                color: $white;
              }
            }
          }

        }
      }
    }
  }
}
