.reveal-overlay {
  z-index: 99999;

  > .reveal {

    &:focus {
      outline: none;
    }
  }

  .close-button {
    z-index: 50;
    cursor: pointer;
    top: 1rem;
    right: 1rem;
    position: absolute;
    font-size: 3rem;
  }
}