.social-media-stripe {

  img {
    margin-left: 1.5rem;
    width: 43px;
    height: 43px;
  }

  h2  {
    line-height: 50px;
  }

}
