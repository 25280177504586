.merchant-search-item {
  border-bottom: 1px solid black;

  .merchant-search-item-logo-wrapper {
    padding-top: 5px;
  }

  @include breakpoint(small down) {

    .boeckmann-merchant-search-list-section & {
      margin-top: 30px;
    }

    .distance {
      display: block;
      height: 20px;
    }
  }

  &:first-of-type {
    border-top: 1px solid black;
  }

  a {
    color: black;
    text-decoration: none;
  }

  a.underline {
    text-decoration: underline;
  }

  a.bold {
    font-family: $font-bold;
  }

  hr {
    margin-top: 0.75rem;
  }

  h3 {
    line-height: 1.375rem;
  }

}

.merchant-search-item-offer-list {
  width: 100px;
  margin: auto;
  text-align: left;
  margin-top: 3rem;
}

.merchant-search-item-distance {
  display: block;
  text-align: right;
}

.merchant-search-item-left-column {
  background-color: #e9e9e9;
}

.merchant-search-item-title {
  margin-top: 1rem;
}

.merchant-search-item-icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.merchant-search-item-icon--small {
  width: 1.1rem;
  height: 1.1rem;
  margin-right: 0rem;

  @include breakpoint(small only) {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
  }

}

.merchant-search-item hr {
  border-bottom: 1px solid black;
}

.merchant-search-item-cell-headline {
  margin-top: 1rem;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
}

.merchant-search-item-cell-headline ~ ul {
  margin-top: 1rem;
}

.merchant-search-item .list-with-title ul li {
  font-size: 1rem;
  padding-left: 0.5rem;
  font-family: $font-roman;
  margin-bottom: 5px;
}

.merchant-search-item .list-with-title ul li:before {
  width: 1rem;
  height: 1rem;
  top: 0;
}

.search-map-filter-content {
  margin-top: 2rem;
}

.reveal-overlay {

  .inner.form-group > label.required {
    border-bottom: 1px solid black;
    margin-bottom: 1rem;
  }

  .button {
    float: right;
  }

  .search-map-filter-submit {
    margin-left: 1rem;
  }

  @media screen and (max-width: 39.9375em) {
    .footer-mobile-sticky {
      width: 100%;
      background-color: white;
      position: fixed;
      bottom: 0px;
      padding-right: 2rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

}

.merchant-map-legend-logo {
  height: 60px;
  width: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 25px;
}


.merchant-search-item-logo {
  max-width: 96px;
  display: block;
  margin: auto;
  margin-top: 10px;

  + .merchant-search-item-logo {
    margin-top: 16px;
  }

  &.center-logo {
    max-width: 112px;
  }
}

.boeckmann-merchant-search-map {

  .selectize-input, input, .button, select {
    height: 43px;
  }

  [data-open="merchantListExpandFilter"],
  [data-open="merchantMapExpandFilter"] {
    margin-top: 25px;
  }

  input[type=checkbox] + label {
    width: 100%;
    font-family: $font-bold;
  }

  .icon-input {
    position: relative;
  }

  .icon-input-after {
    content: '';
    position: absolute;
    right: 15px;
    bottom: 11px;
    width: 23px;
    height: 21px;
    background: url('../../../images/icons/lupe-corpdarkgrey.svg') center center no-repeat;
    background-size: contain;
    z-index: 1;
    cursor: pointer;
  }

}

.marchant-list-filter {
  .selectize-input, input, .button, select {
    height: 43px;
  }

  [data-open="merchantListExpandFilter"],
  [data-open="merchantMapExpandFilter"] {
    margin-top: 25px;
  }

  input[type=checkbox] + label {
    width: 100%;
    font-family: $font-bold;
  }

  .icon-input {
    position: relative;
  }

  .icon-input-after {
      content: '';
      position: absolute;
      right: 15px;
      bottom: 11px;
      width: 23px;
      height: 21px;
      background: url('../../../images/icons/lupe-corpdarkgrey.svg') center center no-repeat;
      background-size: contain;
      z-index: 1;
      cursor: pointer;
  }

}

.custom-map-control-button {
  appearance: button;
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin: 10px;
  padding: 0 0.5em;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
}
.custom-map-control-button:hover {
  background: #ebebeb;
}

#merchantMapStartLocator {
  position: relative;
  &.geolocation_permission_denied {
    cursor: not-allowed;
    &:after {
      content: '';
      top: 40%;
      left: 0;
      position: absolute;
      height: 2px;
      width: 120%;
      display: block;
      background-color: red;
      transform: rotate(30deg) translate(-10%, -50%);
    }
  }
}