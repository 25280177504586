section.product-finder-light {
  padding-bottom: 70px;
  padding-top: 70px;
  background-color: #e9e9e9;

  h2 {
    font-family: "Helvetica Neue Bold Condensed", Arial, Helvetica, sans-serif;
    font-size: 34px;
    font-weight: normal;
    line-height: 1.24;
    color: #424242;

    &.subheadline {
      font-family: "Helvetica Neue Condensed", Arial, Helvetica, sans-serif;
    }
  }

  p {
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.44;
    color: #424242;
  }

  .hline {
    height: 1px;
    background-color: black;
    width: 66%;
    margin: 20px 0px;
  }

  .vline {
    border-right: 1px solid black;
    padding-right: 30px;

    @include breakpoint(small only){
      border-right: none;
      padding-right: 0px;
    }
  }

  .grid-container {
    background-color: white;
    padding: 30px 28px;
    margin: 0 auto;
  }
}

.product-finder-light-wrap{
  min-height: 433px;
  padding: 0px 0px;

  @include breakpoint(medium) {

    padding: 0px 10px;
  }

  .step{

    padding: 10px;
    max-width: 700px;
    margin: auto;

    @include breakpoint(small only){
      padding: 10px 0px;
    }

    .stepwrap{
      display: flex;
      flex-direction: column;
      align-content: space-between;
      min-height: 433px;

      >.selectize-container, >.form-group{
        flex: 1;

        label{
          margin-bottom: 100px;
          font-size: 1.375rem;
        }
      }
    }

    h3{
      height: 42px;
      font-family: "Helvetica Neue Bold", Arial, Helvetica, sans-serif;
      font-size: 1.375rem;
      line-height: 1.91;
      color: #424242;
      margin-bottom: 30px;
    }

  }

  .radioselect{
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-between;

    >div{
      width: 45%;
      max-width: 170px;
      height: 132px;
      @include breakpoint(medium) {
        max-width: 230px;
      }


    }
    label{
      width: 90%;
      @include breakpoint(medium) {
        width: 230px;
      }

      h4{
        font-size: 14px;
        line-height: 20px;
        border-bottom: black solid 1px;
        margin-bottom: 10px;

        &.highlight {
          color: #9b1414;
          border-color: #9b1414;
        }
      }

      .icon{
        max-width: 142px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  /* HIDE RADIO */
  [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* IMAGE STYLES */
  [type=radio] + div {
    cursor: pointer;
    width: 90%;
    height: 82px;
    position: relative;
    display: block;

    @include breakpoint(medium) {
      width: 230px;
    }

    &.lkw {
      background: url('../../../images/icons/fahrzeugart_lkw_light.svg') bottom center no-repeat;
      background-size: contain;;

    }
    &.pfa {
      background: url('../../../images/icons/fahrzeugart_pfa_light.svg') bottom center no-repeat;
      background-size: contain;
    }
    &.pkw {
      background: url('../../../images/icons/fahrzeugart_pkw_light.svg') bottom center no-repeat;
      background-size: contain;
    }
    &.vie {
      background: url('../../../images/icons/fahrzeugart_vie_light.svg') bottom center no-repeat;
      background-size: contain;
    }
  }

  /* CHECKED STYLES */
  [type=radio]:checked + div, [type=radio] + div:hover {
    width: 90%;
    height: 82px;
    position: relative;
    display: block;
    background-size: contain;

    @include breakpoint(medium) {
      width: 230px;
    }

    &.lkw{
      background: url('../../../images/icons/fahrzeugart_lkw_dark.svg') bottom center no-repeat;
      background-size: contain;
    }
    &.pfa{
      background: url('../../../images/icons/fahrzeugart_pfa_dark.svg') bottom center no-repeat;
      background-size: contain;
    }
    &.pkw{
      background: url('../../../images/icons/fahrzeugart_pkw_dark.svg') bottom center no-repeat;
      background-size: contain;
    }
    &.vie{
      background: url('../../../images/icons/fahrzeugart_vie_dark.svg') bottom center no-repeat;
      background-size: contain;
    }

  }

  .finder-light-nav{
    display: flex;
    justify-content: center;
    align-content: flex-end;
    height: 30px;

    @include breakpoint(medium) {
      justify-content: flex-end;
    }
  }

  .finder-light-nav {
    .next{
      font-family: "Helvetica Neue Bold", Arial, Helvetica, sans-serif;
      font-size: rem-calc(14);
      font-weight: normal;
      line-height: 1.25;
      color: #9b1414;
      margin-left: 5px;

      &:after {
        display: inline-block;
        width: 0.25rem;
        content: '';
        padding: 0.25rem;
        border: solid #9b1414;
        border-width: 0 1px 1px 0;
        transform: rotate(-45deg);
        margin-left: 5px;
        position: relative;
        top: -1px;
      }

      @include breakpoint(medium) {
        font-size: rem-calc(16);
      }

    }
    .prev{
      margin-right: 5px;
      font-size: rem-calc(14);
      font-weight: normal;
      line-height: 1.25;
      &:before{
        display: inline-block;
        width: 0.25rem;
        content: '';
        padding: 0.25rem;
        border: solid #424242;
        border-width: 0 1px 1px 0;
        transform: rotate(135deg);
        margin-right: 5px;
        position: relative;
        top: -1px;
      }

      @include breakpoint(medium) {
        font-size: rem-calc(16);
      }

      + div {
        line-height: 1.25;
      }
    }
    button.next{
      margin-top: -10px;
      outline: none;

      @include breakpoint(small down) {
        padding-bottom: 3px;
      }
    }
  }

}

