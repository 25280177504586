.downloads-filter {
  margin-bottom: 30px;

  .filter {
    cursor: pointer;
    position: relative;
    font-size: rem-calc(14);
    padding: 10px 20px 30px 80px;

    &:before {
      opacity: .3;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      margin-top: -16px;
      width: 70px;
      height: 40px;
      background: transparent url('../../../images/icons/icon-horsetruck.png') center bottom no-repeat;
      background-size: contain;
    }

    &.active {
      font-family: $font-bold;

      &:before {
        opacity: 1;
      }
    }

  }

  #ALL {
    padding: 10px 20px 30px 0;
    min-width: 70px;

    &:before {
      display: none;
    }
  }

  #PKW {

    &:before {
      background-image: url('../../../images/icons/icon-pkw.png');
    }
  }

  #PFA {

    &:before {
      background-image: url('../../../images/icons/icon-pferdeanhaenger.png');
    }
  }

  #VIE {

    &:before {
      background-image: url('../../../images/icons/icon-viehanhaenger.png');
    }
  }
}
.downloads-list {

  .no-catalogues {
    font-family: $font-bold;
    margin-bottom: 30px;
    display: none;
  }

  .cat-item {
    margin-bottom: 20px;

    h3 {
      line-height: 1.2;
      margin-bottom: 6px;

      + p {
        font-size: rem-calc(14);
      }
    }

    .download {
      display: inline-block;
      width: 40px;
      height: 40px;
      background: url('../../../images/icons/download.svg') center center no-repeat;
      background-size: contain;
    }
  }

  .cover {

    .checkbox {
      position: relative;

      + label {
        cursor: pointer;
      }
    }

    .row {
      margin-bottom: 0;
    }

    img {
      border: solid 1px $corp-lightgrey;
      border-bottom: none;
    }

    input[type=checkbox] {
      width: 1px;
      height: 1px;
      padding: 0;
      position: absolute;
      margin: 0;
      visibility: hidden;

      + label {
        margin: 0;
        display: block;
        top: 20px;
        left: 20px;
        width: 30px;
        height: 30px;
        position: absolute;
        text-indent: 40px;
        overflow: hidden;

        &:before {
          content: '';
          width: 30px;
          height: 30px;
          background-color: $white;
          border: 1px solid $corp-darkgrey;
          display: block;
          top: 0;
          left: 0;
          position: absolute;
          font-size: 0;
          line-height: 0;
        }

      }

      &:checked {

        + label {

          &:after {
            content: '';
            background: $corp-darkred;
            width: 16px;
            height: 16px;
            position: absolute;
            top: 7px;
            left: 7px;
            font-size: 0;
            line-height: 0;
          }

        }
      }
    }

  }

  .text-layer {
    padding: 20px 20px 20px;
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, .15);
  }

  @include breakpoint(medium) {

  }

  @include breakpoint(large) {


  }
}