.contact-person {

  .contact-person-item {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding-top: 20px;
    padding-bottom: 20px;

    p {
      font-size: rem-calc(14);
    }
  }

  .contact-person-item-cell-headline {
    border-bottom: 1px solid black;
    margin-bottom: 15px;
  }

  .contact-person-item-cell-headline h5 {
    font-family: $font-roman;
    font-size: rem-calc(14);
    height: 20px;
  }

  .contact-person-icon {
    height: 32px;
    width: 32px;
    margin-right: 10px;
  }

  table {
    margin: 0;

    thead, tbody, tfoot {
      border: none;
      background: $white;

      th,td {
        padding: 0;
        font-size: rem-calc(14);

        strong {
          font-family: $font-bold;
          font-weight: normal;
        }
      }
    }

    tr {

      &:nth-child(even) {
        background: $white;
      }
    }

    td {

    }
  }

  @include breakpoint(small) {
    .contact-person-item-cell-headline {
      margin-top: 25px;
    }

    .contact-person-item-cell-headline:nth-child(0) {
      margin-top: 15px;
    }
  }

  + .contact-person {

    .contact-person-item {
      border-top: none;
    }
  }

  &.margin-bottom-small {

    + .contact-person {
      margin-top: -12px;
    }
  }

  &.margin-bottom-medium {

    + .contact-person {
      margin-top: -25px;
    }
  }

  &.margin-bottom-large {

    + .contact-person {
      margin-top: -60px;
    }
  }

  @include breakpoint(large) {

    &.margin-bottom-medium {

      + .contact-person {
        margin-top: -40px;
      }
    }

    &.margin-bottom-large {

      + .contact-person {
        margin-top: -128px;
      }
    }
  }

}

