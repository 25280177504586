.boeckmann-video {
  background: $corp-grey;

  .video-teaser {
    position: relative;
    background: #000;

    &.no-consent {
      background: none;
      .no-cookie {
        display: block;
      }
      .video-image-wrapper {
        display: none;
      }
    }

    .responsive-embed {
      margin-bottom: 0;
    }

    .video-image-wrapper {
      top: 50%;
      left: 50%;
      position: absolute;
      z-index: 8;
      transform: translate(-50%, -50%) scale(1.001);
      overflow: hidden;
      width: 100%;
      height: 100%;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 10;
        background: url('../../../images/icons/play-button.svg') center center no-repeat;
        background-size: 80px 80px;

        @include breakpoint(medium) {
          background-size: 136px 136px;
        }
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 9;
        background-color: rgba(#000, 0.4);
      }

      img {
        height: 100%;
        width: auto;
        max-width: initial;
        display: block;
      }
    }


    &.video-is-playing {
      .video-image-wrapper, .text-wrapper {
        display: none;
      }
    }

    .forbidden {

      iframe {
        display: none;
      }

      ~ .text-wrapper,
      ~ .video-image-wrapper {
        display: none;
      }
    }
    .no-cookie {
      display: none;
      text-align: center;
      position: absolute;
      width: 90%;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: rgba(255, 255, 255, 0.9);
      padding: 30px;
    }
  }
}
