.search-map-wrapper {
  height: 75vh;
  position: relative;

  .no-cookie {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $corp-grey;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .inner {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: rgba(255, 255, 255, 0.9);
      padding: 30px;
    }
  }
  &.no-consent {
    .no-cookie {
      display: block;
    }
  }
}

.search-map-filter {

  .icon-input {

    .search-opened + .page__main & {
      z-index: 0;
    }

  }
}

.search-map-legend {
  background-color: #e9e9e9;
}

.legend-item {
  height: 85px;
  margin-right: 1rem;
}

.legend-item:last-child {
  margin-right: 0;
}

.legend-item img {
  float: left;
}

.legend-item span {
  line-height: 85px;
  font-family: $font-bold;
  margin-left: 1rem;
}

.gmaps-infowindow a {
  color: black;
  text-decoration: none;
}

.gmaps-infowindow a.button {
  padding: 8px 3px 5px;
}

.gm-style-iw {
  padding-bottom: 1rem !important;
  padding-right: 1rem !important;
}

.gm-style-iw-c {
  width: 90vw !important;
  max-width: 1180px !important;
}

.gm-style-iw-d {
  overflow-x: hidden !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.gm-style-iw .merchant-search-item-logo {
  max-width: 120px;

  &.center-logo {
    max-width: 140px;
  }
}

.merchant-list-wrapper,
.gm-style-iw {

  p:last-of-type {
    margin-bottom: 1rem !important;
  }

}

.merchant-list-pageinator {
  margin-top: 1rem;
}

.gm-style-iw-t {

  button {

    &.gm-ui-hover-effect {
      top: 5px !important;
      right: 5px !important;
      width: 36px !important;
      height: 36px !important;

      img {
        width: 36px !important;
        height: 36px !important;
        margin: 0 !important;
      }

    }

  }
}
