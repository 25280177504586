// todo: remove .pimcore_area_boeckmann classes when new toolbox without wrappers is in all branches
.pimcore_area_boeckmann-image-header,
.pimcore_area_boeckmann-image-header-distribute,
.pimcore_area_boeckmann-image-header-sublevel,
.pimcore_area_boeckmann-image-only-header,
.stage-header {
  margin-bottom: 60px;

  @include breakpoint(large) {
    margin-bottom: 76px;
  }

}

.stage-header {
  position: relative;

  .image-background {
    background: transparent 50% no-repeat;
    background-size: cover;
    height: 54vw;
  }

  .card-container {
    position: relative;
    background: $white;
    margin: -50px 20px 0;
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);
  }

  .card {
    background: $white;
    padding: 0 15px;

    &:not(.xxl) {
      text-align: right;
    }

    &.xxl {
      padding: 27px 15px 38px;
    }

    a {
      position: relative;
      display: inline-block;
      font-size: rem-calc(16);
      font-family: $font-bold;
      color: $body-font-color;
      text-decoration: none;
      padding: 10px 20px 10px 5px;
    }

    .linklist {
      text-align: right;
      padding-top: 27px;

      a {
        display: block;
        font-size: rem-calc(14);
        padding: 5px 20px 5px 5px;

        &:after {
          top: 8px;
        }
      }
    }
  }

  .subline {

    &:after {
      content: '';
      height: 1px;
      width: 100%;
      max-width: 230px;
      margin: 20px 0 26px;
      background: $corp-darkgrey;
      display: block;
    }
  }

  .opt-image {
    margin-top: 14px;
  }

  @include breakpoint(large) {
    height: 588px;

    .image-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .card-container {
      background: transparent;
      margin: 0 auto;
      padding-top: 95px;
      box-shadow: none;
    }

    .card {
      margin-bottom: -50px;
      background-color: rgba(255,255,255,.8);
      box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);
      padding: 51px 10px 7px;

      &.full {
        background-color: $white;
      }

      &:not(.xxl) {
        text-align: center;
      }

      &.xxl {
        height: calc(100% + 50px);
        padding: 46px 30px;
      }

      .scroll2, .arr-down {
        padding: 5px 20px 5px 0;

        &:after {
          top: 7px;
        }
      }

    }

    .opt-image {
      margin-top: 42px;
    }

  }

  &.wysiwyg {

    .card {

      a {
        position: initial;
        display: inline;
        font-size: inherit;
        font-family: inherit;
        color: $corp-darkred;
        text-decoration: underline;
        padding: 0;
      }
    }
  }

  .long {

    .card {

      h1 {
        font-size: rem-calc(24);

        @include breakpoint(medium) {
          font-size: rem-calc(34);
        }

        @include breakpoint(intermediate) {
          font-size: rem-calc(36);
        }
      }
    }
  }
}



.scroll-down {
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  cursor: pointer;

  &:after {
    top: 15px;
    right: auto;
    left: 19px;
    border-width: 0 3px 3px 0;
    padding: 10px;
  }

  @include breakpoint(large) {
    position: absolute;
    width: 90px;
    height: 90px;
    background-color: rgba(255,255,255,.9);
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);
    margin-bottom: -50px;

    &:after {
      top: 26px;
      left: 34px;
    }
  }

}

// scroll padding is max-height of sticky-header plus 90px - if we need more breakpoints please add them here
html {
  scroll-padding-top: 170px;

  @include breakpoint(large) {
    scroll-padding-top: 220px;
  }
}
