.page__footer {
  padding-top: 50px;

  .logo-area {
    padding-top: 70px;
    border-top: solid 1px $corp-darkgrey;
    border-bottom: solid 1px $corp-darkgrey;

    .logo {
      text-align: center;
      margin-bottom: 50px;


      a {
        display: inline-block;
      }

      img {
        width: 100%;
        max-width: 160px;
      }
    }
  }

  .contact-area {
    padding: 50px 0 38px;

    a {
      color: inherit;
      text-decoration: none;
    }

    .headline {
      font-family: $font-bold-condensed;
      font-size: rem-calc(22);
      line-height: 1.9;

      + .item-wrapper {
        margin-top: 26px;
      }
    }

    .item-wrapper {
      min-height: 70px;
      margin-bottom: 30px;
    }

    .item {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 34vw;


      &:before {
        content: '';
        position: absolute;
        width: 58px;
        height: 100%;
        margin-left: -80px;
        border-right: solid 1px $corp-darkgrey;
        background-image: url('../../../images/icons/fax.svg');
        background-position: 0 50%;
        background-repeat: no-repeat;
        background-size: 40px 41px;
      }

      &.phone {

        &:before {
          background-image: url('../../../images/icons/phone.svg');
        }
      }

      &.mail {

        &:before {
          background-image: url('../../../images/icons/email.svg');
          background-size: 40px 27px;
        }
      }

      &.address {

        &:before {
          background-image: url('../../../images/icons/stecknadel.svg');
          background-size: 40px 48px;
        }
      }


      strong {
        font-family: $font-bold;
      }

      p {
        font-size: rem-calc(14);
      }
    }

    .some-links {
      display: inline-block;
      width: 100%;

      ul {
        margin: 46px auto 0;
        list-style: none;
        display: flex;
        width: 100%;
        max-width: 248px;
        justify-content: space-between;

        li {
          width: 43px;
          margin-right: 25px;

          &:last-of-type {
            margin-right: 0;
          }

          a {
            display: block;
            height: 43px;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;

            &:hover {
              opacity: .8;
            }

            &.facebook {
              background-image: url('../../../images/icons/facebook.svg')
            }

            &.youtube {
              background-image: url('../../../images/icons/youtube.svg')
            }

            &.xing {
              background-image: url('../../../images/icons/xing.svg')
            }

            &.instagram {
              background-image: url('../../../images/icons/instagram.svg')
            }
          }
        }
      }
    }
  }

  .bottom {
    background: $corp-darkgrey;
    color: $white;
    padding: 26px 0 13px;

    ul {
      margin: 0;
      list-style: none;

      li {
        margin-bottom: 18px;
        font-size: rem-calc(14);

        &:last-of-type {
          margin-bottom: 48px;
        }
      }
    }

    a {
      color: $white;
      text-decoration: none;
    }

    .copyright {
      font-family: $font-bold;
      font-size: rem-calc(14);
    }
  }

  @include breakpoint(medium) {

    .contact-area {

      .headline {
        margin-bottom: 33px;

        + .item-wrapper {
          margin-top: 0;
        }
      }

      .item {
        padding: 0 20px 0 80px;

        &:before {
          margin-left: 0;
          left: 0;
        }
      }

      .some-links {
        max-width: 248px;

        .headline {
          margin-bottom: 44px;
        }

        ul {
          margin-top: 0;
          max-width: initial;

          li {
            margin-right: 15px;
          }
        }
      }
    }

    .logo-area {
      display: flex;
      justify-content: space-between;
      padding-bottom: 70px;

      .logo {
        align-self: center;
        margin-bottom: 0;
        //padding-right: 50px;

        + .logo {
          margin-left: 50px;
        }

        img {
          max-width: 200px;
        }
      }
    }

    .bottom {

      ul {
        display: flex;

        li {
          margin-bottom: 0;
          margin-right: 24px;
        }
      }
    }
  }

  @include breakpoint(large) {

    .bottom {
      padding: 14px 0 10px;
      margin-top: 40px;

      ul {

        li {

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @include breakpoint(large) {

    .bottom {
      margin-top: 60px;
    }
  }
}