/**
 * Globals
 */
@import "globals/mixins";
@import "globals/foundation";
@import "globals/fonts";
@import "globals/tags";
@import "globals/helpers";

/**
 * Layout
 */
@import "layout/page";
@import "layout/navigation";

/**
 * General Setttings for Plugins
 */
@import "components/slickslider_defaults";
$mfp-iframe-ratio: calc(9/16);
@import "~magnific-popup/src/css/main.scss";
@import "~selectize/dist/css/selectize.css";
@import "components/selectize_defaults";
@import "components/magnific_popup_defaults";
/**
 * Components
 */
@import "components/twig_macro";
@import "components/reveal";
@import "components/boeckmann-form";
@import "components/boeckmann-headline-section";
@import "components/boeckmann-intro-text";
@import "components/boeckmann-subline";
@import "components/boeckmann-header";
@import "components/boeckmann-teaser";
@import "components/boeckmann-list";
@import "components/boeckmann-video";
@import "components/boeckmann-slider";
@import "components/pagination";
@import "components/gmaps";
@import "components/boeckmann-trade-fairs";
@import "components/boeckmann-footer";
@import "components/boeckmann-accordion";
@import "components/boeckmann-contact-person";
@import "components/boeckmann-downloads";
@import "components/boeckmann-list-job-item";
@import "components/boeckmann-social-media-stripe";
@import "components/boeckmann-accordion";
@import "components/boeckmann-merchant-search";
@import "components/boeckmann-spare-parts";
@import "components/boeckmann-product-grid-item";
@import "components/boeckmann-sitesearch";
@import "components/boeckmann-used-cars";
@import "components/boeckmann-product-list-item";
@import "components/product-finder-light";
@import "components/boeckmann-quote";

@import "../shop/component/website-base";
@import "../shop/component/product";
@import "../shop/component/shop-image-header";
@import "../shop/component/shop-advertise-bar";
@import "../shop/component/shop-image-text";
@import "../shop/component/shop-tile-teaser";
@import "../shop/component/shop-product-slider";
@import "../shop/component/shop-product-teaser";
@import "../shop/component/line-item";
@import "../shop/component/shop-footer";
@import "../shop/component/search-results";
@import "../job/job-application-page";
