h1, h2, h3, h4, h5, h6 {
  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }
}

h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: $font-bold;
  font-weight: normal;
}

h1, .h1, h2, .h2 {
  font-family: $font-bold-condensed;
  font-size: rem-calc(34);
  line-height: 1.24;
  font-weight: normal;
}

h3, .h3 {
  font-size: rem-calc(22);
  line-height: 1.91;
}

h4, .h4 {
  font-size: rem-calc(18);
  line-height: 1.6;
}

@include breakpoint(large) {

  h1, .h1 {
    font-size: rem-calc(42);
    line-height: 1.19;
  }
}

p, div {
  font-size: rem-calc(14);
  line-height: 1.43;

  @include breakpoint(medium) {
    font-size: rem-calc(16);
  }
}

p {
  &:last-of-type {
    margin-bottom: 0;
  }
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea, select {

  .nozoom & {
    font-size: rem-calc(16);
  }
}


.footnote {
  font-size: rem-calc(11);
  padding-top: 30px;

  sup {
    top: 3px;
    font-size: rem-calc(16);
  }

  @include breakpoint(medium) {
    text-align: right;
  }
}
