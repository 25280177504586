.shop-campaign-swiper {
  --swiper-pagination-bullet-border-radius: 0;
  --swiper-pagination-bullet-width: 80px;
  --swiper-pagination-bullet-height: 4px;
  --swiper-pagination-color: #8b0000;

  width: 100%;
  height: auto;

  @include breakpoint(large up) {
    --swiper-pagination-color: #FFF;
  }

  &.swiper {
    padding-bottom: 50px;
    height: 460px;

    @include breakpoint(large up) {
      padding-bottom: 0;
    }
  }

  .swiper-slide {
    img {
      height: 256px;
      width: 100%;

      object-fit: cover;
      object-position: center;

      @include breakpoint(large up) {
        height: 460px;
      }
    }
  }

  .campaign-swiper-content {
    padding: 20px;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.15);

    @include breakpoint(large up) {
      position: absolute;
      left: 20px;
      bottom: 30px;
      box-shadow: none;
    }

    @include breakpoint(xlarge up) {
      left: 6%;
      bottom: 80px;
    }
  }

  h2 {
    font-size: rem-calc(22px);
    min-height: 80px;

    @include breakpoint(large up) {
      color: $white;
      font-size: rem-calc(48px);
      margin-bottom: 30px;
    }
  }

  .campaign-swiper-cta {
    font-size: 1rem;
    font-weight: bold;
    text-align: right;
    display: block;
    color: $corp-darkred;
    font-family: 'Helvetica Neue Roman';
    text-decoration: none;
    line-height: 1;

    &:after {
      content: "\e901";
      font-family: 'primeicons';
      font-size: rem-calc(12);
      padding-left: 5px;
    }

    @include breakpoint(large up) {
      color: $white;
      display: inline-block;
      background-color: $corp-darkred;
      padding: 18px 18px 13px;
    }
  }
}
