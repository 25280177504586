// related links teaser
.teaser-row {
  margin-bottom: 160px;

  &.related {

    .teaser {
      display: flex;
      align-items: flex-end;
      position: relative;
      background-color: rgba(255, 255, 255, .9);
      box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);
      margin: 0 -20px 20px;
    }

    .image {
      order: 2;
      width: 96px;
    }

    .text-layer {
      order: 1;
      background-color: rgba(255, 255, 255, .9);
      width: calc(100% - 96px);
      padding: 28px 22px 12px 20px;
      position: relative;
    }

    a {
      display: block;

      .text-layer {
        color: $body-font-color;
        font-family: $font-bold;


        span {
          display: inline-block;
          position: relative;
          padding-right: 15px;

          &:after {
            top: auto;
            bottom: 8px;
          }
        }
      }
    }

    @include breakpoint(medium) {

      .image {
        width: 138px;
      }

      .text-layer {
        width: calc(100% - 138px);
      }
    }

    @include breakpoint(large) {

      .teaser {
        position: relative;
        display: block;
        box-shadow: none;
        margin: 0 0 30px;
      }

      .image {
        width: 100%;
      }

      .text-layer {
        position: absolute;
        bottom: 0;
        text-align: right;
        margin-left: 21%;
        width: 71.5%;
        padding: 28px 22px 22px 10px;
        margin-bottom: -30px;
        box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, .15);
      }
    }
  }

  .top-link {
    display: inline-block;
    position: relative;
    border: solid 1px $body-font-color;
    padding: 13px 50px 8px 35px;
    margin-top: 5px;

    &:after {
      top: auto;
      bottom: 15px;
      right: 34px;
    }

    a {
      color: $body-font-color;
      font-family: $font-bold;
      line-height: 1.25;
      text-decoration: none;
    }

    @include breakpoint(large) {
      margin-top: 50px;
    }
  }

  &.hero-teaser {
    background-image: linear-gradient(to bottom, #ffffff 4%, #e9e9e9 97%);
    @include responsive-size($hero-teaser-height);
    padding-top: rem-calc(70);
  }

  &.theme{
    margin-bottom: rem-calc(20);

    @include breakpoint(medium down) {
      .grid-container {
        padding: 0;
      }
    }
  }

  &.image-text-combination {

    figcaption {
      margin-top: rem-calc(10);
      font-family: $font-roman;
      font-size: rem-calc(12);
      line-height: 1.67;
    }
  }
}

.theme-teaser{
  position: relative;
  height: auto;
  min-height: 297px;
  @include responsive-size($theme-teaser-height, null);
  display: block;
  margin-bottom: rem-calc(20);
  text-decoration: none;

  &-image {
    position: relative;
  }

  &-layer {
    padding: rem-calc(22px 18px 17px 18px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);

    @include breakpoint(large) {
      position: absolute;
      bottom: 0;
      right: rem-calc(30);
      width: 300px;
      min-height: 177px;
      background-color: rgba(255, 255, 255, 0.9);
    }

    h3 {
      font-size: rem-calc(22);
      line-height: 1.34;
      font-family: $font-bold;
      position: relative;
      margin-bottom: rem-calc(16);
      color: $body-font-color;
    }

    p {
      font-size: rem-calc(14);
      line-height: 1.43;
      font-family: $font-roman;
      color: $body-font-color;
      flex-grow: 1;
    }
  }

  &-link {
    font-family: $font-bold;
    font-size: rem-calc(16);
    line-height: 1.25;
    text-align: right;
    color: $corp-darkred;
    align-self: flex-end;
    margin-top: 15px;
    &:after {
      @include css-triangle-extended(4px, $corp-darkred, 'right');
      margin-left: 5px;
    }
  }

  &.product-families {

    color: $corp-darkgrey;
    margin-bottom: 52px;
    text-decoration: none;
    min-height: inherit;
    height: auto;

    @include breakpoint(medium) {
      min-height: 597px;
    }

    .theme-teaser-image {
      height: 335px;
      background-image: linear-gradient(to bottom, #e9e9e9 52%, #bcbcbc 71%, #e9e9e9 82%);
      padding-top: rem-calc(22);
      @include breakpoint(medium) {
        min-height: 497px;
      }
    }

    .theme-teaser-layer {
      align-items: inherit;
      width: 100%;
      @include breakpoint(medium) {
        width: 430px;
      }
    }
    ul {
      list-style: none;
      margin-left: 0;

      li {
        font-size: rem-calc(14);
        line-height: 1.43;
        &:before {
          content: '-';
          text-indent: -5px;
          margin-right: 5px;
        }
      }
    }
  }

  &-price {
    font-family: $font-bold;
    font-size: rem-calc(34);
    line-height: 0.94;
    color: $corp-darkgrey;
    text-align: right;

  }
}



.hero-teaser2 {

  margin-right: -1.25rem;
  margin-left: -1.25rem;

  @include breakpoint(medium) {
    margin-right: 0;
    margin-left: 0;
  }

  &-contentbox {
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    padding: rem-calc(50px 30px 30px 30px);

    .headline {
      font-family: $font-bold-condensed;
      font-size: rem-calc(28px);
      line-height: 1.21;
      position: relative;
      padding-bottom: rem-calc(22px);
      margin-bottom: rem-calc(24px);
      display: inline-block;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: rem-calc(230);
        height: 1px;
        background: $corp-darkgrey;
        left: 0;
      }
    }

    p {
      font-family: $font-roman;
      font-size: rem-calc(14px);
      line-height: 1.43;
      flex-grow: 1;
      margin-bottom: rem-calc(20px);
    }

    .btn-container {
      align-self: flex-end;
      justify-self: flex-end;
      @include breakpoint(large) {
        width: 50%;
      }
    }

    .button {
      text-decoration: none;
      text-align: left;
      padding-left: rem-calc(23px);
      padding-right: rem-calc(30px);
      min-height: rem-calc(45px);
      position: relative;
      margin-bottom: rem-calc(20px);

      &:after {
        position: absolute;
        right: rem-calc(23px);
        top: rem-calc(17px);
      }
    }
  }

  &-contentbox-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
}

.blog-teaser {
  @include breakpoint(xlarge) {
    padding-bottom: 120px;
  }

  @include breakpoint(small down) {
    .grid-container {
      padding: 0;
    }
  }

  &-big, &-small {
    text-decoration: none;
    color: $corp-darkgrey;
  }

  &-big {
    margin-bottom: rem-calc(20);
  }

  &-small {
    display: block;
    position: relative;
    margin-bottom: rem-calc(20);
  }

  &-date {
    font-size: rem-calc(13);
    line-height: 1.54;
    margin-bottom: rem-calc(20);
  }

  &-category {
    display: inline-block;
    background: $corp-yellow;
    min-width: rem-calc(113);
    padding: rem-calc(4px 9px 0 9px);
    font-family: $font-bold;
    font-size: rem-calc(16px);
    line-height: 1.25;
    margin-bottom: rem-calc(4);
    align-self: flex-start;
    margin-left: rem-calc(-9px);
  }

  &-headline {
    font-family: $font-bold-condensed;
    font-size: rem-calc(28px);
    line-height: 1.21;
    position: relative;
    padding-bottom: rem-calc(22px);
    margin-bottom: rem-calc(24px);
    display: inline-block;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: rem-calc(230);
      height: 1px;
      background: $corp-darkgrey;
      left: 0;
    }
  }

  .btn-container {
    align-self: flex-end;
    justify-self: flex-end;
  }

  &-big-contentbox {
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;
    padding: rem-calc(18px 18px 17px 18px);

    @include breakpoint(large) {
      padding: rem-calc(50px 30px 30px 30px);
    }

    .content-wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      color: $corp-darkgrey;
      height: 100%;
    }

    p {
      font-family: $font-roman;
      font-size: rem-calc(14px);
      line-height: 1.43;
      flex-grow: 1;
      margin-bottom: rem-calc(20px);
    }

    .button {
      text-decoration: none;
      text-align: left;
      padding-left: rem-calc(23px);
      padding-right: rem-calc(30px);
      min-height: rem-calc(45px);
      position: relative;
      margin-bottom: rem-calc(20px);

      &:after {
        position: absolute;
        right: rem-calc(23px);
        top: rem-calc(17px);
      }

      @include breakpoint(small down) {
        border: none;
        color: $corp-darkred;
        font-family: $font-bold;
        font-size: rem-calc(16);
        line-height: 1.25;
        padding: 0;
        min-height: inherit;
        margin-bottom: 0;

        &:after {
          @include css-triangle-extended(4px, $corp-darkred, 'right');
          margin-left: 5px;
          position: relative;
          right: auto;
          top: -1px;
        }
      }
    }
  }

  &-image {
    @include breakpoint(large) {
      min-height: 250px;
    }
    @include breakpoint(xlarge) {
      min-height: 324px;
    }
  }

  &-layer {
    padding: rem-calc(18px 18px 17px 18px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);

    @include breakpoint(medium) {
      min-height: 212px;
    }

    @include breakpoint(xlarge) {
      position: absolute;
      bottom: rem-calc(-130);
      right: rem-calc(30);
      width: 518px;
      min-height: 212px;
      background-color: rgba(255, 255, 255, 0.9);
      justify-content: space-between;
    }

    h3 {
      font-size: rem-calc(28);
      line-height: 1.21;
      font-family: $font-bold-condensed;
      position: relative;
      margin-bottom: rem-calc(15);
      color: $body-font-color;
      flex-grow: 1;
    }

    .blog-teaser-link {
      color: $corp-darkred;
      font-family: $font-bold;
      font-size: rem-calc(16);
      line-height: 1.25;

      &:after {
        @include css-triangle-extended(4px, $corp-darkred, 'right');
        margin-left: 5px;
        position: relative;
        top: -1px;
      }
    }
  }
}

.offer-teaser-new {
  padding-bottom: 32px;

  .label {
    font-family: $font-bold;
    margin: 20px 0 5px;
  }

  .offer-selector {
    position: relative;
    min-height: 42px;

    .select-menu {
      margin: 0 42px 0 0;
      border: solid 1px $corp-darkgrey;
      z-index: 9;

      li {
        padding: 11px 4px 7px 8px;
        background-color: $white;
        cursor: pointer;
        display: none;

        @include breakpoint(medium) {
          padding: 10px 4px 5px 8px;
        }

        &:not(.active):hover {
          background-color: $light-gray;
        }

        &.active {
          display: block;
        }
      }

      &.open {
        position: absolute;
        min-height: 42px;
        width: 100%;

        li {
          display: block;

          &.active {
            background-color: $light-gray;
          }
        }
      }
    }

    .selectbox-submitter {
      position: absolute;
      margin: 0;
      right: 0;
      bottom: 0;
      width: 42px;
      height: 42px;
      background: $corp-darkgrey;
      cursor: pointer;
      z-index: 19;

      &:after {
        @include css-triangle-extended(6px, $white, 'right');
        position: relative;
        top: -2px;
        left: -3px;
      }

      &:hover,
      &:focus {
        background: $corp-darkgrey-hover;
        outline: none;
      }
    }
  }

  .boeckmann-form {

    .selectize-container {

      &.with-submit {
        position: relative;
        padding-right: 44px;
        margin-right: 0;
      }
    }
  }

  .main__content & {
    margin-bottom: 0;
  }

  .offer {

    a {
      color: inherit;
      text-decoration: none;

      &:focus {
        outline: transparent;
        background: $white;
      }
    }
  }

  h3 {
    font-size: rem-calc(16);
    margin-bottom: 22px;
  }

  .teaser {
    position: relative;
    padding: 0;
    background-image: linear-gradient(to bottom, #e9e9e9 52%, #bcbcbc 71%, #e9e9e9 82%);


    .text-layer {
      position: relative;
      padding: 16px 30px 22px 20px;
      box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);
      background-color: rgba(255, 255, 255, 0.9);

      h3 {
        line-height: 1.2;
        padding: 8px 0;
      }

      p {
        font-size: rem-calc(14);
      }
    }
  }


  @include breakpoint(medium) {

    .main__content & {
      margin-bottom: 20px;
    }

    .teaser {
      max-width: 580px;
      margin: 0 0 80px;
      padding: 0 10px 20px;

      .text-layer {
        padding: 11px 30px 7px 20px;
        position: absolute;
        bottom: 0;
        margin-left: 22%;
        width: 69.5%;
        margin-bottom: -50px;
      }
    }

    .outer {
      position: relative;
      width: 100%;
      margin: 0 auto;
      max-width: rem-calc(1440);
      overflow: hidden;

      &:before, &:after {
        content: '';
        position: absolute;
        top: -3px;
        left: 0;
        height: 100%;
        width: 12%;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 90%);
        z-index: 1;
      }

      &:after {
        left: auto;
        right: -1px;
        transform: rotate(180deg);
      }

      .slick-list {
        overflow: visible;
      }
    }
  }

  @include breakpoint(large) {

    .main__content & {
      margin-bottom: 128px;
    }

    .product-helper-price-preis {
      font-size: rem-calc(34);
    }

    .teaser {
      margin: 0 0 56px;
      padding: 0 10px;

      .text-layer {
        position: absolute;
        bottom: 0;
        margin-left: 22%;
        width: 69.5%;
        margin-bottom: -50px;
      }
    }
  }
}

// distributions page teaser
.distribution-page  {

  &.level-1,
  &.level-2 {

    a {
      color: inherit;
      text-decoration: none;
    }

    .teaser {
      position: relative;
      margin-bottom: 40px;

      .image {
        display: none;
      }

      h2 {
        line-height: 1.4;
        margin-bottom: 2px;
      }

      .product-img {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      .text-layer {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        padding: 16px 20px 12px;
        box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);
        background-color: rgba(255, 255, 255, 0.9);

        .short {
          margin-bottom: 34px;
          font-size: rem-calc(14);
        }

        p {
          font-size: rem-calc(14);
        }
      }
    }

    @include breakpoint(large) {

      .teaser {
        margin-bottom: 100px;

        .image {
          display: block;
        }

        .img-text {
          position: absolute;
          bottom: 0;
          margin-bottom: -50px;
          width: 100%;
          padding: 0 20px 0 10px;
          display: flex;
          flex-wrap: nowrap;
        }

        .text-layer {
          max-width: 300px;
        }

      }
    }
  }

  .teaser-link {
    margin-top: auto;
    color: $corp-darkred;
    font-family: $font-bold;
    text-align: right;

    &:after {
      display: inline-block;
      width: 0.25rem;
      content: '';
      padding: 0.25rem;
      border: solid $corp-darkred;
      border-width: 0 1px 1px 0;
      transform: rotate(-45deg);
      margin-left: 5px;
      position: relative;
      top: -1px;
    }
  }

  &.level-2 {

    .teaser {

      .image {
        display: block;
        background-image: linear-gradient(to bottom, #e9e9e9 52%, #bcbcbc 71%, #e9e9e9 82%);
      }

      .text-layer {
        padding: 16px 20px 2px;

        .short {
          margin-bottom: 18px;
        }
      }

      .price-section {
        text-align: right;
      }
    }

    @include breakpoint(medium) {

      .teaser {

        .price-section {
          margin-top: auto;
        }
      }
    }

    @include breakpoint(large) {

      .teaser {

        .image {
          padding-bottom: 80px;
        }

        .img-text {
          width: 100%;
          padding: 0 30px 0 20%;
          display: block;
        }

        .text-layer {
          max-width: 100%;
        }

      }
    }
  }

  .price-section {

    .price {
      display: inline-block;
      font-family: $font-bold;
      font-size: rem-calc(22);
      padding-top: 10px;

      span {
        font-size: rem-calc(18);

        &.inkl {
          font-size: rem-calc(14);
          margin-top: -5px;
          display: block;
        }
      }

      sup {
        font-size: 1em;
        top: -.3em;
      }
    }

    .inkl {
      font-family: $font-bold;
      font-size: rem-calc(14);
      margin-top: -5px;
      display: block;
      padding-bottom: 10px;
    }

    .price--crossed {
      display: inline-block;
      font-family: $font-bold;
      font-size: rem-calc(16);
      color: #666;
      text-decoration: line-through;
      margin-right: 16px;
      padding-top: 10px;

      span {
        font-size: rem-calc(14);
      }

    }

    @include breakpoint(large) {

      .price {
        font-size: rem-calc(34);

        &.request {
          font-size: rem-calc(24);
          line-height: 2;
        }

        span {
          font-size: rem-calc(24);
        }
      }

      .price--crossed {
        font-size: rem-calc(24);
      }
    }
  }

}