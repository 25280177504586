/* TODO: NOT IDEAL PLACED */
.sale-list-legend {
  text-align: right;
  font-size: rem-calc(11);
  line-height: 1.55;
  color: $corp-darkgrey;
  padding: 20px 0 14px;

  sup {
    top: 3px;
    font-size: rem-calc(16);
  }
}

.sale-list-filter {
  margin: 0 0 20px;
  border-bottom: solid 1px $corp-darkgrey;
}
/* TODO: NOT IDEAL PLACED */
.sale-list-filter-anzahl {
  font-size: rem-calc(14);
  line-height: 1.43;
  color: $corp-darkgrey;
  padding-bottom: 13px;
}

.product-grid-item {
  margin-bottom: 20px;
  position: relative;

  > .grid-y {
    height: 100%;
    color: inherit;
    text-decoration: none;
  }

  .product-grid-item-header {
    background-color: $corp-grey;
    text-align: center;
    position: relative;
    padding: 40px 20px 20px;
  }

  .product-grid-item-vergleich {

    margin-top: -10px;
    position: absolute;
    top: 10px;
    left: 0px;
    padding-left: 45px;
    padding-top: 15px;
    padding-bottom: 10px;
    text-decoration: underline;
    font-family: $font-condensed;
    color: $corp-darkgrey;
    cursor: pointer;

    &.disabled {
      text-decoration: line-through;
      cursor: default;
    }

    &:before {
      content: '';
      position: absolute;
      left: 10px;
      top: 9px;
      width: 29px;
      height: 17px;
      padding-top: 25px;
      padding-bottom: 10px;
      background: url('../../../images/icons/vergleichen.svg') center center no-repeat;
      background-size: contain;
    }

    &.active {

      &:before {
        left: 14px;
        top: 7px;
        width: 17px;
        height: 14px;
        background-image: url('../../../images/icons/check.svg');

      }
    }
  }

  .product-grid-item-name {
    font-size: rem-calc(22);
    padding-right: 48px;
  }

  .product-grid-item-shadowwrapper {
    position: relative;
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);
    padding: 18px 20px 12px;
    margin-top: auto;

    &.badge {

      &:before {
        content: '';
        position: absolute;
        right: 20px;
        top: 0;
        margin-top: -42px;
        width: 44px;
        height: 82px;
        background: url('../../../images/icons/price-tag.svg') center center no-repeat;
        background-size: contain;
      }
    }

  }

  .product-grid-item-mehrwerte {
    list-style: none;
    margin: 3px 0 1rem 0;
    font-size: rem-calc(14);
    line-height: 1.43;
  }

  .product-grid-item-footer {
    text-align: right;
    margin-top: auto;
  }

  .product-grid-item-mwst {
    font-size: 12px;
    line-height: 1.67;
    color: $corp-darkgrey;
  }

  .product-grid-item-preis-legend {
    font-size: 20px;
  }

}

