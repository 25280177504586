/*

Flexbox Sticky Footer
https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/

*/
.page {
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.page__header {
		background-color: $white;
		top: 0;
		width: 100%;
		z-index: 1030;
		@include responsive-size($height-header);

		@include breakpoint(large) {
			transition: height 0.3s ease-out;

			&.search-opened {
				z-index: 1;
			}

		}

		.logo {

			@include responsive-size($height-logo, $width-logo);
			margin-top: rem-calc(20);
			position: relative;
			z-index: 100;
		}

		.searchbox-layer {
			overflow-x: hidden;
			display: none;
			background: rgba(66,66,66,0.75);
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			line-height: normal;
			position: fixed;
			z-index: 1000;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			pointer-events: none;
			opacity: 0;
			transition: opacity 0.5s;

			.searchbox-closer {
				position: absolute;
				top: 50px;
				right: 50px;
				width: 50px;
				height: 50px;
				cursor: pointer;

				.leftright{
					height: 4px;
					width: 50px;
					position: absolute;
					margin-top: 24px;
					background-color: $white;
					border-radius: 2px;
					transform: rotate(45deg);
					transition: all .3s ease-in;
				}

				.rightleft{
					height: 4px;
					width: 50px;
					position: absolute;
					margin-top: 24px;
					background-color: $white;
					border-radius: 2px;
					transform: rotate(-45deg);
					transition: all .3s ease-in;
				}

				&:hover {

					.leftright{
						transform: rotate(-45deg);
					}

					.rightleft{
						transform: rotate(45deg);
					}
				}
			}

			.searchbox-content {
				opacity: 0;
				transform: scale3d(0.75, 0.75, 1);
				transition: opacity 0.5s, transform 0.4s;
				margin: 80px 0;
				@include responsive-size(null, $searchbox-breakpoints);
				padding: rem-calc(25);

				h4 {
					font-size: rem-calc(44);
					font-family: $font-bold;
					color: $white;
					margin-bottom: rem-calc(20);
				}

				input {
					@include responsive-size($search-input-height, $search-input-width);
					border: none;
					color: $corp-darkgrey;
					padding: 10px 20px;

					@include breakpoint(large) {
						background-size: 40px auto;
					}
				}

				.icon-input {
					position: relative;
				}

				.icon-input-after {
					content: '';
					position: absolute;
					right: 15px;
					bottom: 25px;
					width: 70px;
					height: 45px;
					background: url('../../../images/icons/lupe-corpdarkgrey.svg') center center no-repeat;
					background-size: contain;
					z-index: 1;
					cursor: pointer;

					@include breakpoint(medium only) {
						bottom: 10px;
						width: 20px;
						height: 25px;
					}

				}

				#searchbox-autocomplete-container {
					.sitesearch-autocomplete-item {
						padding: rem-calc(25);
						background-color: $white;
						margin: 0 0 20px;
						border-bottom: solid 1px #424242;
					}
				}

			}
		}

		&.search-opened {

			.searchbox-layer {
				z-index: 201;
				pointer-events: auto;
				opacity: 1;
			}

			.searchbox-content {
				opacity: 1;
				transform: scale3d(1, 1, 1);
			}
		}
	}

	.header-meta {
		@include responsive-size($height-meta-header);
		background-color: $corp-darkgrey;
		color: $white;
		transition: height 0.3s ease-out;
		padding-top: 6px;

		.menu {

			li {

				> a {
					color: $white;
					font-size: rem-calc(14);
				}
			}
		}
		.search-opener {
			color: $white;
			font-size: rem-calc(14);
			padding: rem-calc(8 16 8 18);
			cursor: pointer;
			background: url('../../../images/icons/lupe.svg') no-repeat left 10px;
			background-size: 13px auto;
		}

		.language-selector {
			padding: rem-calc(0 16 8 0);
		}
	}

	.main-header {
		max-width: 100vw;
		background: $white;
		box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.15);

		@include breakpoint(large) {
			//box-shadow: none;
			box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.15);
			&.opened {
				//box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.15);
			}
		}

		@include responsive-size($height-main-header);
		position: relative;
		z-index: 100;
	}

	.page__main {
		flex-grow: 1;
	}

	.main__stage {
		margin-bottom: rem-calc(30);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(60);
		}
	}

}

