.advertise-bar {
  .cell {
    text-align: center;
    background-color: #9B1414;
    color: white;
    padding: 14px 24px 12px;
    font-size: .875rem;

    img {
      height: 26px;
      width: auto;
      margin-right: 15px;
    }
  }
}
