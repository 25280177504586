.job_application_form {
  /* Styling for the drop area and file list */
  #drop-area {

    border: 2px dashed #ccc;
    border-radius: 10px;
    width: 100%;
    min-height: 100px;
    padding: 20px;
    text-align: center;
    margin: 20px 0;
    color: #999;
    &.upload-error {
      border-color: red;
    }
  }

  #drop-area.hover {
    border-color: #333;
    color: #333;
  }

  #file-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #file-list li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
  }

  #file-list li button {
    background-color: #9b1414;
    text-align: center;
    border: none;
    color: white;
    padding: 7px 10px 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }

  #file-list li button:hover {
    background-color: #ff1a1a;
  }
}