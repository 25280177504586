.used-cars {

  &-list {

    .tabs, .tabs-content {
      border: 0;
    }

    .tabs {
      z-index: 1;
      position: relative;
      background-color: transparent;
    }

    .tabs-title {

      a {
        padding: 5px 35px 18px 8px;
        font-size: 14px;
        text-decoration: none;
        background-color: $corp-grey;
        font-family: $font-bold;

        &:focus {
          outline: transparent;
        }
      }

      &:not(.is-active):hover a {
        background-color: lighten($corp-grey, 5%);
      }

      &.is-active a {
        z-index: 2;
        position: relative;
        border-collapse: separate;
        background-color: #fff;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
      }

      a:before {
        content: '';
        margin-right: 10px;
        width: 43px;
        height: 25px;
        display: inline-block;
        transform: translateY(25%);
        background: transparent url('../../../images/icons/fahrzeugart_pkw_dark.svg') no-repeat center center;
        background-size: contain;
      }

      &.lkw a:before {
        background-image: url('../../../images/icons/fahrzeugart_lkw_dark.svg');
      }

      &.pfa a:before {
        background-image: url('../../../images/icons/fahrzeugart_pfa_dark.svg');
      }

      &.vie a:before {
        background-image: url('../../../images/icons/fahrzeugart_vie_dark.svg');
      }
    }

    .content-select-wrap {
      margin-bottom: 50px;
      position: relative;
      border-top: 1px solid $corp-darkgrey;
      border-bottom: 1px solid $corp-darkgrey;

      > .grid-container {
        z-index: 2;
        position: relative;
        padding-top: 40px;
        padding-bottom: 30px;
        background-color: $white;
      }

      @supports (background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0) 100%) ) {
        border-top: 0;
        border-bottom: 0;

        &:before, &:after {
          content: '';
          display: block;
          height: 30px;
          width: 100%;
          left: 0;
          position: absolute;
          opacity: 0.05;
        }
        &:before {
          top: 0;
          transform: translateY(-100%);
          background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0) 90%);
        }
        &:after {
          bottom: 0;
          transform: translateY(100%);
          background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,0,0,0) 90%);
        }
      }

    }

    .boeckmann-form {

      .submit-row {
        padding-top: 25px;

        .button {
          padding: 15px 40px 10px;
        }
      }
    }

    .sale-list-pageinator {
      margin-top: 20px;
    }

    @include breakpoint(medium down) {

      .tabs {
        display: flex;
        flex-wrap: wrap;

        .tabs-title {
          width: 50%;

          a {
            border: solid 1px $line-grey;
          }

          &:nth-child(2) a {
            border-width: 1px 1px 1px 0;
          }

          &:nth-child(3) a {
            border-width: 0 1px 1px 1px;
          }

          &:nth-child(4) a {
            border-width: 0 1px 1px 0;
          }

          &.is-active a {
            box-shadow: none;
          }
        }
      }
    }

    @include breakpoint(small down) {

      .tabs {

        .tabs-title {
          width: 100%;

          &:nth-child(2) a {
            border-width: 0 1px 1px 1px;
          }

          &:nth-child(3) a {
            border-width: 0 1px 1px 1px;
          }

          &:nth-child(4) a {
            border-width: 0 1px 1px 1px;
          }
        }
      }
    }

  }

  &-item {
    padding: 12px 20px;
    font-size: rem-calc(14);
    position: relative;
    width: calc(100% + 40px);
    margin: 0 -20px;

    &:nth-child(even) {
      background: $corp-grey;
    }

    > a {
      color: inherit;
      text-decoration: none;
    }

    .used-image {
      text-align: center;
      margin-bottom: 10px;
      padding-top: 8px;
    }

    .category {
      font-style: italic;
      margin-bottom: 5px;
    }

    .description {
      height: 50px;
      overflow: hidden;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-image: linear-gradient(to bottom,rgba(255,255,255,0),#fff 100%);
      }
    }

    &:nth-child(even) {

      .description {

        &:after {
          background-image: linear-gradient(to bottom,rgba(233,233,233,0),$corp-grey 100%);
        }
      }

    }

    .name {
      padding-top: 8px;
      font-family: $font-bold;
      font-size: rem-calc(14);
    }

    p, span {
      font-size: rem-calc(14);
      line-height: rem-calc(20);
    }

    .h4 {
      line-height: 1.4;
      margin: 6px 0;
    }

    .price {
      display: block;
      font-size: rem-calc(22);
      line-height: rem-calc(24);
      font-family: $font-bold;
      margin-top: auto;
      text-align: right;

      .tax {
        display: block;
        font-family: $font-roman;
        font-size: rem-calc(11);
        line-height: rem-calc(16);
        margin-top: 8px;
      }

      sup {
        font-size: .55em;
        top: -1em;
      }
    }
  }

}
