// todo: remove .pimcore_area_boeckmann classes when new toolbox without wrappers is in all branches
.pimcore_area_boeckmann-headline-section,
.headline-section {

  .subline {

    &:after {
      content: '';
      height: 1px;
      width: 100%;
      max-width: 230px;
      margin: 20px 0 26px;
      background: $corp-darkgrey;
      display: block;
    }

    @include breakpoint(medium) {
      flex: 0 0 auto;
      margin-right: 30px;
      padding-right: 30px;
      padding-top: 5px;
      border-right: solid 1px $corp-darkgrey;

      &:after {
        display: none;
      }

      + p {
        align-self: center;
        padding-top: 5px;
      }
    }
  }

}