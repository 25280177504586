
.product-line-item {
  border-bottom: 1px solid #CBCBCB;
  padding: 16px 0;

  .product-column-image {
    img {
      height: auto;
      max-height: 100px;
    }
  }
}

.product-item--name {
  margin-bottom: 16px;

  &,
  & .product-item-link {
    font-family: $font-bold;
    text-decoration: none;
    color: $form-label-color;
  }
}

.product-item--sku,
.product-item--properties,
.product-item--variant,
.product-item--amount {
  font-size: rem-calc(12);
}

.product-item--sku,
.product-item--variant,
.product-item--amount {
  color: #807E7E;
}

.product-item--delivery {
  display: flex;
  flex: 1 0 auto;
  align-items: center;

  .icon-svg {
    margin-top: -4px;
  }

  .title-text {
    margin-right: 5px;
    font-weight: bold;
  }
}

.product-item--total {
  font-size: rem-calc(26);
  font-family: $font-bold;
  text-align: right;

  .strike-price {
    color: #9B1414;
    font-size: 54%;
    top: -3px;
    margin-right: 6px;
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      top: 4px;
      left: -2px;
      position: absolute;
      background: #9B1414;
      transform: rotate(-13deg);
    }
  }

  .base-price {
    display: block;
    font-family: $font-roman;
    font-size: rem-calc(11);
    color: #807E7E;
    margin-bottom: 5px;
  }

  .price-request {
    font-size: 1.125rem;
    line-height: 1;
    display: block;
  }

  .vat-rate {
    display: block;
    font-size: .7rem;
    padding-bottom: 10px;
    font-weight: lighter;
    font-family: $font-roman;

  }
}

.product-item--quantity {
  display: flex;
  place-content: flex-end;
}
